import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {

  public static instance: ScriptLoaderService;

  protected _libraries: HTMLElement[];


  public static getInstance(): ScriptLoaderService {
    if (!ScriptLoaderService.instance){
      ScriptLoaderService.instance = new ScriptLoaderService();
    }
    return ScriptLoaderService.instance;
  }


  constructor() {
    this._libraries = [];
    ScriptLoaderService.instance = this;
  }


  removeLibrary(l: HTMLElement): HTMLElement {
    document.head.removeChild(l);

    const i: number = this._libraries.indexOf(l);
    return this._libraries.splice(i , 1)[0];
  }

  removeAllLibraries(): void {
    this._libraries.forEach(l => this.removeLibrary(l) );
  }

  addLibrary(l: HTMLElement ): HTMLElement {
    this._libraries.push(l);
    document.head.appendChild(l);
    return l;
  }

  loadScript(src: string , module: boolean = false): HTMLScriptElement {
    const oScript: HTMLScriptElement = document.createElement('script');
    oScript.async = false;
    module ? oScript.type = 'module' : void(0) ;
    oScript.src = src;
    return this.addLibrary(oScript) as HTMLScriptElement;
  }

  loadCSS(src: string ): HTMLLinkElement {
    const oLink: HTMLLinkElement = document.createElement('link');
    oLink.rel = 'stylesheet' ;
    oLink.href = src;
    return this.addLibrary(oLink) as HTMLLinkElement;
  }
}
