import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {MediaCollectionComponent} from 'projects/visitor/src/app/components/media/media-collection/media-collection.component';
import {MediaImage, MediaLocation} from 'projects/library/src/model/item-model';

@Component({
  selector: 'app-media-location-collection',
  templateUrl: './media-location-collection.component.html',
  styleUrls: ['./media-location-collection.component.scss']
})
export class MediaLocationCollectionComponent  extends MediaCollectionComponent implements OnInit, OnDestroy, AfterViewInit {

  public selectedLocation: MediaLocation;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  collectionIndexOnChanged(index: number): void {
    super.collectionIndexOnChanged(index);

    this.selectedLocation = this.selectedItem as MediaLocation;
  }


}
