<div class="info-container {{lang.direction}}" >

<!--
  <div style="position: absolute; display: flex; flex-direction: row; justify-content: end">
    <img [src]="itemService.item.icon" style="max-width: 40%; clip-path: circle(49% at center);" >
  </div>
-->

  <img [src]="itemService.item.icon" class="img-icon" >

  <section class="section" *ngFor="let term of translatableTerms">
    <div class="label">{{languageService.translationGet('ITEM_DESCRIPTION' , term , lang.code) }}:</div>
    <div>{{languageService.translate(textItem , term , lang.code) }}</div>
  </section>

</div>
