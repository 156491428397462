export function getEnumKeys(oEnum: any): string[] {
  const keys: string[] = [];
  for (const v in oEnum) {

    // Number -> valor
    const isNumber: boolean = parseInt(v, 10) >= 0 ;
    if(isNumber) continue;

    // String lower -> value
    const firstChar: string = v.charAt(0);
    const isLower: boolean = firstChar === firstChar.toLowerCase();
    if (isLower) continue;

    // String + upper -> KEY
    keys.push(v);
  }
  return keys;
}


export function getEnumValues(oEnum: any): any[] {
  const arrKeys: string[] = getEnumKeys(oEnum);
  const values: any[] = [];
  for (const k of arrKeys) {
    const v: any = oEnum[k];
    values.push(v);
  }
  return values;
}

