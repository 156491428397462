<div #container class="media-collection" >
  <img #right class="media-image-dual" [src]="selectedCompare?.right">
  <img #left class="media-image-dual" [src]="selectedCompare?.left" [style]="getStyle()">

<!--
  <img #left class="media-image-dual" [src]="selectedCompare?.left" style="polygon(0 0 , {{sliderValue}}% 0, {{sliderValue}}% 100%, 0 100%);">
-->


  <input #slider class="media-image-slider-compare" type="range" min="0" max="100" [(ngModel)]="sliderValue" style="width: calc( {{left.offsetWidth}}px + 40px);" >

</div>

<!--overlay con la controladora de la galeria-->
<app-collection-controller [collection]="collection" (indexSelected)="collectionIndexOnChanged($event)" ></app-collection-controller>
