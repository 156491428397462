/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Injectable } from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {Observable, concat, of, Subject} from 'rxjs';
import {LoggerInterface} from 'projects/library/src/services/logger/logger.interface';
import {JsonLoaded} from 'projects/library/src/services/json/json-loaded';
import {HttpLoadStatus} from 'projects/library/src/services/http-loader/http-load-status';
import {HttpLoaderService} from 'projects/library/src/services/http-loader/http-loader.service';
import {LoggerService} from 'projects/library/src/services/logger/logger.service';




@Injectable({
  providedIn: 'root'
})
export class JsonService {





  /* CONFIG ********************************************************** */

  public static __config: any;





  // logger
  private logger: LoggerInterface;

  // Paths
  // public jsonPathTemplate: string;


  // Emit every loaded file
  public loaded$: Subject<JsonLoaded>;

  public status: HttpLoadStatus;





  public static setConfig(d: any): void {
    JsonService.__config = d;
  }

  public static getConfig(): any {
    return JsonService.__config ;
  }

  public get config(): any {
    return JsonService.getConfig() ;
  }



  constructor(
    private httpLoaderService: HttpLoaderService,
    private loggerService: LoggerService
  ) {

    // log
    this.logger = this.loggerService.getLogger('JsonService');

    // Emitter of just loaded jsons.
    this.loaded$ = new Subject<JsonLoaded>();

    // Load Status
    this.status = {
      initialized: true ,
      message: 'Json collection loaded'
    };

    // PATHS
    // this.jsonPath = JsonService.__basePath ;
  }



  /**
   * Load ALL Api collections with Angular CONCAT (One after another)
   * Resturn a Promise as is required by APP_INITIALIZER in app.module
   */
  public initialLoad( arrCollectionNames: string[] ): Observable<any> {

    // transform conceptNames in file names
    const arrFiles: string[] = arrCollectionNames.map( concept => this.getJsonPath( concept ) ) ;

    // transform fileNames in load observables.
    const arrObservables: Observable<any>[] = arrFiles.map( path => this.httpLoaderService.httpLoad( path )  ) ;


    return concat ( ...arrObservables).pipe(

      // Capture any load error....
      catchError(e => {
        this.logger.ENABLED && this.logger.error('initialLoad ERROR : ' + e );

        // Set error status
        this.setLoadError('Load Error :: ' + e );

        return of(null);
      }),

      // and once a file is loaded, emit the response.
      map((value, index) => {

        const jsonLoaded: JsonLoaded = {
          concept: arrCollectionNames[index] ,
          path: arrFiles[index] ,
          data: value
        };

        if (!jsonLoaded.data){
          this.setLoadError('Empty object :: ' + jsonLoaded.path  );
        }

        this.loaded$.next(jsonLoaded);
      })
    );
  }

  private setLoadError(msg: string): void {
    this.status.initialized = false;
    this.status.message = msg;
  }



  public getJsonPath(collectionName: string ): string {
    return './assets/json/' + collectionName + '.json';
    // return this.jsonPathTemplate.replace('${concept}' , collectionName )  ;
  }



}
