import { Component, OnInit } from '@angular/core';
import {SettingsService} from 'projects/visitor/src/app/services/settings/settings.service';
import {LanguageService} from 'projects/library/src/services/language/language.service';

@Component({
  selector: 'app-settings-editor',
  templateUrl: './settings-editor.component.html',
  styleUrls: ['./settings-editor.component.scss']
})
export class SettingsEditorComponent implements OnInit {

  constructor(
    public settingsService: SettingsService,
    public languageService: LanguageService
  ) { }

  ngOnInit(): void {
    //
  }

}
