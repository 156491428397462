import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QrPipe } from './qr/qr.pipe';
import { CropPipe } from './crop/crop.pipe';
import { TimestampToDate } from './timestamp/timestamp-to-date.pipe';
import { ReplacerPipe } from './replacer/replacer.pipe';
import { FilterPipe } from './filter/filter.pipe';
import {SafePipe} from 'projects/library/src/pipes/safe/safe.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    SafePipe,
    CropPipe,
    TimestampToDate,
    ReplacerPipe,
    FilterPipe,
    QrPipe
  ],
  exports: [
    SafePipe,
    QrPipe,
    CropPipe,
    TimestampToDate,
    ReplacerPipe,
    FilterPipe
  ]
})
export class PipesModule {}
