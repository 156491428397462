<div class="info-container {{lang.direction}}" style="padding: 0" >

  <mat-nav-list>

    <ng-container  *ngFor="let rel of linkItem.items">

      <mat-list-item (click)="relationClick(rel)">
        <img mat-list-icon [src]="rel.icon">
        <span mat-line>{{languageService.translate(rel , 'title' , lang.code ) }}</span>
      </mat-list-item>
      <mat-divider></mat-divider>

    </ng-container>



  </mat-nav-list>

</div>
