/* tslint:disable:no-bitwise whitespace */
import {ISubtitleParser} from 'projects/library/src/subtitles/parser/i-subtitle-parser';
import {IAudioSyncMetadata} from 'projects/library/src/audioPlayer/implementation/i-audio-player';

export class SubtitleParserMarks implements ISubtitleParser {
  public cues: VTTCue[] ;
  private _data: { [key: string]: IAudioSyncMetadata };

  private _byteToCharTable: number[];
  private _charToByteTable: number[];

  constructor(
    protected media: HTMLMediaElement,
    protected text: string
  ) {
    this.bytesParse();
  }



  // Dado un texto, realiza un mapa de relacion entre el byteIndex y el charIndex.
  private bytesParse(): void {
    this._byteToCharTable = [];
    this._charToByteTable = [];

    let byteIndex: number = 0;
    for (let charIndex: number = 0 ; charIndex < this.text.length ; charIndex++ ){
      const charCode: number = this.text.charCodeAt(charIndex);
      const numBytes: number = Math.ceil(charCode / 127);

      this._charToByteTable[charIndex] = byteIndex ;
      this._byteToCharTable[byteIndex] = charIndex ;

      byteIndex += numBytes;
    }
  }


  private bytesToChars(byteIndex: number): number {
    return this._byteToCharTable[byteIndex];
  }



  public parse(t: string): void {
    const arrLines: string[] = t.split('\n' );

    const arrData: IAudioSyncMetadata[] = [];
    for (const l of arrLines){
      if (l.length === 0 ){
        continue;
      }
      const oJson: IAudioSyncMetadata = JSON.parse(l);
      oJson.time /= 1000; // En segundos...
      oJson.start = this.bytesToChars(oJson.start);
      oJson.end = this.bytesToChars(oJson.end) || t.length; // el ultimo caracter parece que NO se procesa asi que le pongo el text.length

      arrData.push(oJson);
    }

    // Añadimos una ultima vacía de cada tipo, para asegurarnos que el ultimo CUE limpia las pantallas....
    const oLast: IAudioSyncMetadata = {
      start: t.length ,
      end: t.length,
      value: ''
    };
    arrData.push(Object.assign({type: 'word' , time: this.media.duration - 0.02} , oLast ));
    arrData.push(Object.assign({type: 'sentence' , time: this.media.duration - 0.01} , oLast ));


    this.cues = [];
    this._data = {};

    // tslint:disable-next-line:prefer-for-of
    for (let i: number = 0 ; i < arrData.length ; i++ ){
      const id: string = 'cue_' + i ;

      const oCurrent: IAudioSyncMetadata = arrData[i];
      const oNext: IAudioSyncMetadata = arrData[i + 1];

      const start: number = oCurrent.time;
      const end: number = oNext ? oNext.time : this.media.duration;
      const text: string = oCurrent.value;

      try{
        const oCue: VTTCue = new VTTCue(start , end , text );
        oCue.id = id;
        this.cues.push(oCue);
      }catch (e){
        console.log(e);
      }

      this._data[id] = oCurrent;
    }
  }


  public findData(cue: TextTrackCue): IAudioSyncMetadata {
    const oSync: IAudioSyncMetadata =  this._data[cue.id];
    return oSync;
  }


}
