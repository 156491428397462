import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {LoggerInterface} from 'projects/library/src/services/logger/logger.interface';
import {LoggerService} from 'projects/library/src/services/logger/logger.service';

export interface LatencyInterface {
  latency: number;
  offset: number;
}


@Injectable({
  providedIn: 'root'
})
export class LatencyService {


  public static __config: any;

  private _logger: LoggerInterface;

  public current$: BehaviorSubject<LatencyInterface>; //
  public update$: BehaviorSubject<LatencyInterface>;
  public complete$: BehaviorSubject<LatencyInterface>; // EventEmitter<LatencyInterface>;
  public error$: EventEmitter<string>;


  private _latencyData: LatencyInterface = {
    latency: 100000000000000000000000 ,
    offset: 0
  };

  private _currentData: LatencyInterface = {
    latency: 0 ,
    offset: 0
  };

  private _retryCounter: number;
  private _retryInterval: number[];
  private _retryCompleteAt: number;
  private _retryMax: number;
  private _unitFactor: number;
  public completeProgress: number;


  private _timeRetryBind: any;



  public static setConfig(d: any): void {
    LatencyService.__config = d;
  }
  public static getConfig(): any {
    return LatencyService.__config ;
  }



  constructor() {
    this._logger = LoggerService.getLogger('Latency Service') ;

    this.current$ = new BehaviorSubject<LatencyInterface>(this._currentData);
    this.update$ = new BehaviorSubject<LatencyInterface>(this._latencyData);
    this.complete$ = new BehaviorSubject<LatencyInterface>(null); // new EventEmitter<LatencyInterface>();
    this.error$ = new EventEmitter<string>();

    this._timeRetryBind =  this.timeRetry.bind(this);
    this.completeProgress = 0;

    // this.reset();
    this.restart();
  }



  private reset(): void {
    this._retryCounter = 1;
    this._retryCompleteAt = this._config.retryCompleteAt;
    this._retryInterval = this._config.retryInterval;
    this._retryMax = this._retryInterval.length;
    this._unitFactor = this._config.unitFactor;
  }


  public get latencyData(): LatencyInterface {
    return this._latencyData;
  }

  public get currentData(): LatencyInterface {
    return this._currentData;
  }


  private get _config(): any {
    return LatencyService.getConfig() ;
  }


  public timestampGet(): number {
    return Date.now();
  }



  private async timeRequest(): Promise<void> {
    const t1 = this.timestampGet() ;

    let serverTimeCurrent: number = await fetch(this._config.url)
      .then(response => response.json() )
      .then(response => response.now )
      .catch(err => {
        this._logger.ENABLED && this._logger.error('TIME NOT Found');
        return 0;
      });

    // Multiply byt ffactor
    serverTimeCurrent *= this._unitFactor;

    const t2 = this.timestampGet() ;
    const roundTimeTrip = t2 - t1;

    const latencyCurrent: number = roundTimeTrip / 2;
    serverTimeCurrent += latencyCurrent;
    const serverTimeOffsetCurrent: number = t2 - serverTimeCurrent;

    this._currentData.latency = latencyCurrent;
    this._currentData.offset = serverTimeOffsetCurrent;
    this._logger.ENABLED && this._logger.log('latencyCurrent: ' , this._currentData.latency , this._currentData.offset  );
    this.current$.next(this._currentData);


    if (latencyCurrent < this._latencyData.latency){
      this._latencyData.latency = latencyCurrent;
      this._latencyData.offset = serverTimeOffsetCurrent;

      this.update$.next(this._latencyData);
      this._logger.ENABLED && this._logger.log('latencyImproved: ' ,  JSON.stringify(this._latencyData) );
    }
  }



  private async timeRetry(): Promise<void> {
    await this.timeRequest();

    // complete? emit value (but keep retrying until end...
    if (this._retryCounter === this._retryCompleteAt){
      this._logger.ENABLED && this._logger.log('complete');
      this.complete$.next(this._latencyData);
      // this.complete$.complete();
    }


    // Hemos llegado al limite? (entendemos que ya no se puede mejorar mas ) ....
    if (this._retryCounter >= this._retryMax){
      this._logger.ENABLED && this._logger.log('retry limit');
      return;
    }

    // Aun no hemos llegado al limite? reintentamos....
    const newDelay: number = this._retryInterval[this._retryCounter] ;
    setTimeout( this._timeRetryBind , newDelay);
    this._logger.ENABLED && this._logger.log( 'retry' , this._retryCounter , newDelay );

    this._retryCounter++;
    this.completeProgress = this._retryCounter /  this._retryCompleteAt ;
  }



  public start(): void {
    this.timeRetry();
  }

  public restart(): void {
    this.reset();
    this.start();
  }

  public async refresh(): Promise<void> {
    this.timeRequest();
  }




  // Hora NTP actual
  public ntpTime(time?: number): number {
    if (!time){
      time = this.timestampGet();
    }
    return time + this._latencyData.offset ;
  }


}
