import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {MediaItem, MediaItemCollection} from 'projects/library/src/model/item-model';
import {TipService} from 'projects/visitor/src/app/services/tip/tip.service';
import {ScriptLoaderService} from 'projects/library/src/services/script-loader/script-loader.service';
import {Subscription} from 'rxjs';
import {ILanguage} from 'projects/library/src/services/language/i-language';
declare var ResizeObserver;


@Component({
  selector: 'app-media-collection',
  templateUrl: './media-collection.component.html',
  styleUrls: ['./media-collection.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MediaCollectionComponent implements OnInit, OnDestroy, AfterViewInit {

  protected _subs: Subscription[];
  protected _timeouts: number[];
  private _itemLanguage: ILanguage;


  @Output()
  itemSelected: EventEmitter<MediaItem> = new EventEmitter<MediaItem>();

  @Output()
  tip: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  id: string;


  @Input()
  set collection(c: MediaItemCollection<MediaItem>) {
    this._collection = c;
    this.collectionOnReady();
  }
  get collection(): MediaItemCollection<MediaItem> {
    return this._collection;
  }


  @Input()
  set itemLanguage(l: ILanguage) {
    this._itemLanguage = l;
    this.itemLanguageOnChanged(l);
  }
  get itemLanguage(): ILanguage {
    return this._itemLanguage;
  }


  @ViewChild('container')
  containerRef: ElementRef;
  protected container: HTMLDivElement;



  protected _collection: MediaItemCollection<MediaItem>;

  public selectedIndex: number;
  public selectedItem: MediaItem;

  protected _observer: any ;


  protected _libraries: HTMLElement[];

  protected _scriptLoader: ScriptLoaderService;

  constructor(
    //
  ) {
    this._subs = [];
    this._timeouts = [];
    this.selectedIndex = 0;
    this.selectedItem = null;
    this._libraries = [];
    this._scriptLoader = ScriptLoaderService.getInstance();
  }



  ngOnInit(): void {
    console.log('inited');
  }

  ngOnDestroy(): void {
    this._subs.forEach(s => s.unsubscribe());
    this._timeouts.forEach(n => window.clearTimeout(n) );

    this._observer?.unobserve(this.container);
    this._observer?.disconnect();
    this._libraries.forEach(l => document.head.removeChild(l) );
  }

  ngAfterViewInit(): void {

    this.container = this.containerRef.nativeElement;
    this._observer = new ResizeObserver( this.containerOnResized.bind(this) );

    // Solo observamos el segundo cuadro
    this._observer.observe(this.container);

    this.tipTrigger();
  }


  protected collectionOnReady(): void {
    // this.collectionIndexOnChanged(0);
  }

  protected containerOnResized(entries): void {
    //
  }

  collectionIndexOnChanged(index: number): void {
    this.selectedIndex = index;
    this.selectedItem = this.collection.items[this.selectedIndex];
    this.itemSelected.emit(this.selectedItem);
  }

  protected itemLanguageOnChanged(l: ILanguage): void {

  }


  protected tipTrigger(): void {
    setTimeout( () => {
      this.tip.emit(this.id);
    } , 10 );
  }



  addLibrary(l: HTMLScriptElement | HTMLLinkElement ): void {
    this._libraries.push( this._scriptLoader.addLibrary(l) );
  }

  loadScript(src: string , module: boolean = false): void {
    this.addLibrary(this._scriptLoader.loadScript(src , module));
  }


  loadCSS(src: string ): void {
    this.addLibrary( this._scriptLoader.loadCSS(src) ) ;
  }

}
