import {IAudioPlayer, IAudioSyncMetadata} from 'projects/library/src/audioPlayer/implementation/i-audio-player';
import {EventEmitter} from '@angular/core';
import gsap from 'gsap';
import {ILanguage} from 'projects/library/src/services/language/i-language';
import {AudioItemFile} from 'projects/library/src/model/item-model';
import {SubtitleParserFactory} from 'projects/library/src/subtitles/parser/subtitle-parser-factory';
import {ISubtitleParser} from 'projects/library/src/subtitles/parser/i-subtitle-parser';
import {Subject} from 'rxjs';



export class AudioPlayerImplementationFile implements IAudioPlayer{

  public complete$: EventEmitter<boolean>;
  public loaded$: EventEmitter<boolean>;
  public position$: EventEmitter<number>;
  public error$: EventEmitter<string>;
  public metadata$: Subject<IAudioSyncMetadata>;
  public spectrum$: EventEmitter<number[]>;


  private _audio: HTMLAudioElement;
  private _audioURL: string;
  private _context: AudioContext;
  private _analyser: AnalyserNode;
  private _analyserSource: MediaElementAudioSourceNode;
  private _fft: Uint8Array;
  private _spectrum: number[];


  private _subtitleParser: ISubtitleParser;
  private _subtitleTrack: TextTrack;


  private _onTimeUpdate: any;
  private _onTimeEnded: any;
  private _onPlaying: any;
  private _onPaused: any;
  private _onCanPlayThrough: any;
  private _playing: boolean = false;


  constructor(
    private text: string,
    private lang: ILanguage,
    private descriptor: AudioItemFile
  ) {
    this.position$ = new EventEmitter<number>();
    this.complete$ = new EventEmitter<boolean>();
    this.loaded$ = new EventEmitter<boolean>();
    this.error$ = new EventEmitter<string>();
    this.metadata$ = new Subject<IAudioSyncMetadata>();
    this.spectrum$ = new EventEmitter<number[]>();

    // Cargamos rul (audio) y metadata (srt)
    this._onTimeUpdate = this.onTimeUpdate.bind(this);
    this._onTimeEnded = this.onTimeEnded.bind(this);
    this._onPlaying = this.onPlaying.bind(this);
    this._onPaused = this.onPaused.bind(this);
    this._onCanPlayThrough = this.onCanPlayThrough.bind(this);

    this.load();
  }

  public async load(): Promise<void> {

    try{
      const oAudioRequest: Request = new Request(this.descriptor.url);
      const oAudioBlob: Blob = await fetch(oAudioRequest).then(response => response.blob() );
      this._audioURL = URL.createObjectURL(oAudioBlob);

      this._audio = new Audio();
      this._audio.addEventListener('timeupdate' , this._onTimeUpdate );
      this._audio.addEventListener('ended' , this._onTimeEnded );
      this._audio.addEventListener('playing' , this._onPlaying );
      this._audio.addEventListener('pause' , this._onPaused );
      this._audio.addEventListener('canplaythrough' , this._onCanPlayThrough );
      this._audio.src = this._audioURL ;

    }catch (e: any){
      this.error$.emit(e);
    }
  }

  private async metadataLoad(): Promise<void> {
    if (!this.descriptor.metadata){
      return;
    }

    try{
      const oMetadataRequest: Request = new Request(this.descriptor.metadata);
      const oMetadataText: string = await fetch(oMetadataRequest).then(response => response.text() );

      // Tomamos un parser en base al sufijo
      const ext: string = this.descriptor.metadata.toLowerCase().split(/[#?]/)[0].split('.').pop().trim();

      this._subtitleParser = SubtitleParserFactory.instanceGet(ext , this._audio , this.text);
      this._subtitleParser.parse(oMetadataText);

      this._subtitleTrack = this._audio.addTextTrack('captions');
      this._subtitleTrack.mode = 'hidden';
      this._subtitleParser.cues.forEach( c => this._subtitleTrack.addCue(c));
      this._subtitleTrack.oncuechange =  this.metadataCueOnChanged.bind(this) ;

    }catch (e) {
      console.log(e);
    }
  }


  private metadataCueOnChanged(e: Event): void {
    let tt: TextTrack;
    let tc: TextTrackCue;
    let d: IAudioSyncMetadata;

    tt = e.target as TextTrack ;
    if (tt.activeCues && tt.activeCues[0] ){

      tc = tt.activeCues[0];
      d = this._subtitleParser.findData(tc);

      this.metadata$.next(d);

    }
  }



  private createContext(): void {
    const dataSize: number = 512;
    this._context = new AudioContext();
    this._analyser = this._context.createAnalyser();
    this._analyser.fftSize  = dataSize * 2  ;
    this._analyserSource = this._context.createMediaElementSource(this._audio);
    this._analyserSource.connect(this._analyser);
    this._analyserSource.connect(this._context.destination);
    this._fft = new Uint8Array(this._analyser.frequencyBinCount);
  }




  private setPlaying(b: boolean): void {
    this._playing = b;
  }

  private onTimeUpdate(e?: Event): void {
    // this.setPlaying(true);
    this.position$.emit(this._audio.currentTime);
  }


  private onTimeEnded(e): void {
    this.setPlaying(false);
    this.complete$.emit(true);
  }

  private onPlaying(): void {
    this.setPlaying(true);
    this.getSpectrum();
 }


  private onPaused(): void {
    this.setPlaying(false);
  }

  private onCanPlayThrough(): void {
    this.loaded$.emit(true);

    this.metadataLoad();
  }



  private computeSpectrum(): void {
    this._analyser.getByteFrequencyData(this._fft);
    this._spectrum = [...this._fft];
    this.spectrum$.emit(this._spectrum);
  }


  private getSpectrum(): void {
    if (!this._analyser) { return; }

    this.computeSpectrum();

    if (this.playing) {
      requestAnimationFrame( () => {
        this.getSpectrum();
      } );
    } else {
      this.fadeSpectrum();
    }
  }

  private fadeSpectrum(): void {
    // bajamos gradualmente los valores y seguimos emitiendo...
    gsap.to( {foo : 255} , {
      duration: 1,
      foo: 0,
      onUpdate: () => {
        for (let i = 0 ; i < this._spectrum.length ; i++){
          const v = this._spectrum[i];
          if (v > 0){
            this._spectrum[i]--;
          }
        }
        // this.spectrum$.emit(this._spectrum);
        this.computeSpectrum();
      }
    } );
  }






  public setLoop(b: boolean): void {
    this._audio.loop = b;
  }

  public getDuration(): number {
    return this._audio.duration;
  }

  public pause(): void {
    console.log('AudioPlayerIMplementationFile.payse' , this._audio );
    this._audio?.pause();
  }

  public async play(ms: number = 0): Promise<void> {

    if (!this._context){
      this.createContext();
    }

    try{
      this._audio ? this._audio.currentTime = ms / 1000 : void(0) ;
      this._audio?.play();

    }catch (e){
      this.error$.emit(e);
    }
  }


  public resume(): void {
    if (!this._context){
      this.createContext();
    }

    try{
      this._audio?.play();
    }catch (e){
      this.error$.emit(e);
    }
  }

  public seek(ms: number): void {
    this. _audio.currentTime = ms / 1000; // fastSeek(ms);
  }

  public clear(): void{
    try{
      URL.revokeObjectURL(this._audioURL);
      this._audio?.removeEventListener('timeupdate' , this._onTimeUpdate );
      this._audio?.removeEventListener('ended' , this._onTimeEnded );
      this._audio?.removeEventListener('playing' , this._onPlaying );
      this._audio?.removeEventListener('pause' , this._onPaused );
      this._audio?.removeEventListener('canplaythrough' , this._onCanPlayThrough );

      this._subtitleTrack ? this._subtitleTrack.oncuechange = null : void(0) ;

      this._audio?.pause();
      this._audio?.removeAttribute('src'); // empty source
      this._audio?.load();
    }catch (e){
      //
    }
  }


  public get playing(): boolean {
    return this._playing;
  }


  muteToggle(): void{
    this._audio.muted = !this._audio.muted;
  }
  public get muted(): boolean {
    return this._audio ? this._audio.muted : false ;
  }

}
