import {AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {InfoItem} from 'projects/library/src/model/item-model';
import {LanguageService} from 'projects/library/src/services/language/language.service';
import {Subscription} from 'rxjs';
import {ILanguage} from 'projects/library/src/services/language/i-language';

@Component({
  selector: 'app-info-abstract-item',
  templateUrl: './info-abstract-item.component.html',
  styleUrls: ['./info-abstract-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoAbstractItemComponent implements OnInit, OnDestroy, AfterViewInit {

  protected _subs: Subscription[];
  protected _timeouts: number[];

  private _item: InfoItem ;
  private _lang: ILanguage;


  @Input()
  set item(c: InfoItem) {
    this._item = c;
    this.itemOnReady();
  }
  get item(): InfoItem {
    return this._item;
  }

  @Input()
  set lang(l: ILanguage) {
    this._lang = l;
    this.itemLanguageOnChanged();
  }
  get lang(): ILanguage {
    return this._lang;
  }





  constructor(
    public languageService: LanguageService
  ) {
    this._subs = [];
    this._timeouts = [];
  }

  ngOnInit(): void {
    //
  }

  ngOnDestroy(): void {
    //
  }

  ngAfterViewInit(): void {
    this._subs.forEach(s => s.unsubscribe());
    this._timeouts.forEach(n => window.clearTimeout(n) );
  }


  protected itemOnReady(): void {
    //
  }


  protected itemLanguageOnChanged(): void {

  }


}
