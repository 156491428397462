import { Component, OnInit } from '@angular/core';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {NavigationService} from 'projects/library/src/services/navigation/navigation.service';
import {NavigationSegments} from 'projects/visitor/src/app/navigation/navigation-segments.enum';
import {FavoritesService} from 'projects/visitor/src/app/services/favorites/favorites.service';
import {LanguageService} from 'projects/library/src/services/language/language.service';
import {ItemService} from 'projects/library/src/services/item/item.service';
import {Favorite} from 'projects/library/src/model/item-model';
import {ItemInfoType} from 'projects/library/src/model/item-info-type.enum';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit {

  public favorites: Favorite[] = [];
  public filterPlaceholder: string = '';

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<FavoritesComponent> ,
    private navigationService: NavigationService,
    public favoritesService: FavoritesService,
    public languageService: LanguageService,
    public itemService: ItemService,
    private _snackBar: MatSnackBar
  ) {
    //
  }


  ngOnInit(): void {
    this.filterPlaceholder = 'search here'
    this.clear();
  }

  open(f: Favorite): void {
    this.navigationService.navigateTo( NavigationSegments.ITEM , f.id );
    this._bottomSheetRef.dismiss();
  }

  add(): void {
    const newFavorite: Favorite = {
      id: this.itemService.item.itemID,
      icon: this.itemService.item.icon ,
      glossaryCollection: this.itemService.item.information.find( i => i.type === ItemInfoType.DATA)?.glossaryCollection
    };
    const b: boolean = this.favoritesService.add(newFavorite);
    this.clear();

    this.confirm('FAVORITES_ADD' , b );
  }


  remove(f: Favorite): void {
    const b: boolean = this.favoritesService.remove(f);
    this.clear();

    this.confirm('FAVORITES_REMOVE' , b );
  }


  clear(): void {
    this.favorites = this.favoritesService.favorites;
  }

  filter(str: string): void {
    this.favorites = this.find(str.toLowerCase());
  }

  private find(needle: string): Favorite[] {
    return this.favoritesService.favorites
      .filter( f => this.languageService.translate(f , 'title' , this.itemService.language.code )
        .toLowerCase().indexOf(needle) >= 0
      );
  }

  private confirm(tag: string , b: boolean): void {
    this._snackBar.open(this.languageService.translationGet(tag , 'ack' ) , null , {duration: 3000 });
  }

}
