import {AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NavigationService} from 'projects/library/src/services/navigation/navigation.service';
import {LanguageService} from 'projects/library/src/services/language/language.service';
import {ScannedUrl} from 'projects/library/src/model/scanned-url';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AudioEffectsService} from 'projects/visitor/src/app/services/audio-effects/audio-effects.service';

declare var QrScanner;

enum CameraMode {
  ENVIRONMENT = 'environment',
  USER = 'user',
}
interface Camera {
  id: string;
  label: string;
}

@Component({
  selector: 'app-scanner-dialog',
  templateUrl: './scanner-dialog.component.html',
  styleUrls: ['./scanner-dialog.component.scss']
})
export class ScannerDialogComponent implements OnInit, OnDestroy, AfterViewInit {


  public CameraMode = CameraMode;
  public currentMode: CameraMode;
  private scanner: any;
  public message: string;
  protected video: HTMLVideoElement;
  protected canvas: HTMLCanvasElement;
  protected beep: HTMLAudioElement;

  @Output()
  closed: EventEmitter<void> = new EventEmitter<void>();


  @ViewChild('canvasContainerRef')
  canvasContainerRef: ElementRef;
  protected canvasContainer: HTMLDivElement;

  constructor(
    private audioFxService: AudioEffectsService,
    private navigationService: NavigationService,
    public languageService: LanguageService,
    public dialogRef: MatDialogRef<ScannerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public scannedUrl: ScannedUrl
  ){
   this.message = this.languageService.translationGet('SCANNER' , 'waiting' );
   this.scannedUrl = {
      valid: false,
      url: ''
   };
  }



  ngOnInit(): void {
    console.log('Scanner inited');
  }


  ngOnDestroy(): void {
    try{
      this.video.pause();
      this.video.removeAttribute('src');
      this.video.load();
      this.scanner?.stop();
      this.scanner?.destroy();
    }catch (e){
      console.log('destroying scanner video' , e);
    }
  }


  ngAfterViewInit(): void {
    this.video = document.createElement('video');
    this.beep = document.createElement('audio');


    this.canvasContainer = this.canvasContainerRef.nativeElement as HTMLDivElement ;
    this.scannerOnReady();
  }


  async scannerOnReady(): Promise<void> {

    try{

      const hasCamera: boolean = await QrScanner.hasCamera(); // async
      if (!hasCamera){
        alert('No camera is detected on this device');
        this.dialogRef.close(null);
        return;
      }

      this.scanner = new QrScanner(this.video, this.scannerOnData.bind(this) );
      this.cameraSet(CameraMode.ENVIRONMENT);

      this.scanner.start();

      this.canvas = this.scanner.$canvas;
      this.canvas.style.width = '100%';
      this.canvas.style.height = '100%';
      this.canvasContainer.appendChild(this.canvas);

    }catch (e) {
      console.log('Error creating QR videoStream' , e );
    }
  }


  scannerOnData( sUrl: string): void {
    const oldUrl: string = this.scannedUrl.url;

    const oUrl: URL = new URL(sUrl);
    this.scannedUrl.url = sUrl ;
    this.scannedUrl.valid = oUrl.host === location.host;

    // Nueva y valida? pitido
    if (this.scannedUrl.url !== oldUrl && this.scannedUrl.valid ){
      this.audioFxService.play('scan');
    }

  }

  navigate(): void {
    this.navigationService.navigateExternal(this.scannedUrl.url);
    this.closed.emit();
  }

  cameraToggle(): void {
    const otherMode: CameraMode = this.currentMode === CameraMode.ENVIRONMENT ? CameraMode.USER : CameraMode.ENVIRONMENT ;
    this.cameraSet(otherMode);
  }

  cameraSet(mode: CameraMode): void {
    this.currentMode = mode;
    this.scanner.setCamera(this.currentMode);
    this.message = this.currentMode;
  }



}
