import { NgModule } from '@angular/core';
import {Routes, RouterModule, RouteReuseStrategy} from '@angular/router';
import {HomeComponent} from 'projects/visitor/src/app/components/home/home.component';
import {ErrorComponent} from 'projects/visitor/src/app/components/error/error.component';
import {ItemComponent} from 'projects/visitor/src/app/components/item/item.component';
import {NavigationSegments} from 'projects/visitor/src/app/navigation/navigation-segments.enum';






const routes: Routes = [
  {
    path: NavigationSegments.ROOT,
    redirectTo: NavigationSegments.HOME,
    pathMatch: 'full'
  },
  {
    path: NavigationSegments.HOME,
    component:  HomeComponent,
    data: {animation: 'Home'}
  },
  {
    path: NavigationSegments.ERROR,
    component:  ErrorComponent,
    data: {animation: 'Error'}
  },
  {
    path: NavigationSegments.ITEM + '/:id',
    component:  ItemComponent,
    data: {animation: 'Item'}
  }
];


@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      useHash: true,
    }
  )],
  providers: [
/*
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy
    }
*/
  ],  exports: [RouterModule]
})
export class RoutingModule {


  constructor() {
    //
  }

}
