<div class='home-container'>

  <h1>Bienvenido a Prosodik</h1>

  <div class="home-info" >

    <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vulputate, velit non ornare dictum, nunc metus rhoncus libero, id finibus ex erat id elit. Vivamus convallis viverra elit at laoreet. Sed ut elit id felis cursus semper sit amet eu dui. Nullam maximus ornare dictum.
    </p>

    <img style="text-align: center" src="./assets/instructions/toolbar.png">

    <p>
    Pellentesque efficitur egestas arcu in porta. Phasellus luctus mi a iaculis laoreet. Quisque vehicula, mauris sit amet aliquam faucibus, turpis lectus congue ipsum, non gravida eros nulla sed massa. Ut placerat vestibulum lacus, nec vestibulum felis posuere porttitor.
    </p>

    <img style="text-align: center" src="./assets/instructions/graphics.png">

    <p>
    Vivamus convallis viverra elit at laoreet. Sed ut elit id felis cursus semper sit amet eu dui. Nullam maximus ornare dictum. Vestibulum nec faucibus risus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ac odio feugiat metus luctus ullamcorper. Duis malesuada non metus eget auctor.
    </p>

  </div>


</div>

