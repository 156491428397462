export enum SubtitleParserTypesEnum {

  MARKS = 'marks',
  VTT = 'vtt',
  SRT = 'srt',
  JSON = 'json',
  XML = 'xml',
  CSV = 'csv'

}
