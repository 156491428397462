/* eslint-disable @typescript-eslint/no-inferrable-types */
import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';

import { Subscription } from 'rxjs';
import {LoggerInterface} from 'projects/library/src/services/logger/logger.interface';
import {ErrorService} from 'projects/library/src/services/error/error.service';
import {NavigationService} from 'projects/library/src/services/navigation/navigation.service';
import {JsonService} from 'projects/library/src/services/json/json.service';
import {LanguageService} from 'projects/library/src/services/language/language.service';
import {LoggerService} from 'projects/library/src/services/logger/logger.service';
import {NavigationSegments} from 'projects/visitor/src/app/navigation/navigation-segments.enum';
import {AudioEffectsService} from 'projects/visitor/src/app/services/audio-effects/audio-effects.service';
import {FullscreenService} from 'projects/visitor/src/app/services/fullscreen/fullscreen.service';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ScannerDialogComponent} from 'projects/visitor/src/app/components/dialogs/scanner-dialog/scanner-dialog.component';
import {ScannedUrl} from 'projects/library/src/model/scanned-url';
import {ShareComponent} from 'projects/visitor/src/app/components/share/share.component';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConfirmDialogData} from 'projects/library/src/model/confirm-dialog-data';
import {ConfirmDialogComponent} from 'projects/visitor/src/app/components/dialogs/confirm-dialog/confirm-dialog.component';
import {LocalStorageService} from 'projects/visitor/src/app/services/local-storage/local-storage.service';
import {SessionStorageService} from 'projects/visitor/src/app/services/session-storage/session-storage.service';
import {NosleepService} from 'projects/visitor/src/app/services/nosleep/nosleep.service';
import {FavoritesComponent} from 'projects/visitor/src/app/components/favorites/favorites.component';
import {FavoritesService} from 'projects/visitor/src/app/services/favorites/favorites.service';
import {ItemService} from 'projects/library/src/services/item/item.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  private _logger: LoggerInterface;
  private _subs: Subscription[];
  private _onFirstClick: any = this.onFirstClick.bind(this);

  private scannedUrl: ScannedUrl = {
    valid: false,
    url: ''
  };


  constructor(
    private _errorService: ErrorService,
    private _navigationService: NavigationService,
    private _jsonService: JsonService,
    public languageService: LanguageService,
    public fullscreenService: FullscreenService,
    public nosleepService: NosleepService,
    public sessionStorageService: SessionStorageService,
    public favoriteService: FavoritesService,
    public itemService: ItemService,
    private _audioFxService: AudioEffectsService,
    public dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
  ) {
    this._logger = LoggerService.getLogger('AppComponent');
    this._subs = [];
  }




  ngOnInit(): void {
    this._logger.ENABLED && this._logger.info('ngOnInit');

    if (!this._jsonService.status.initialized){
      this._errorService.add(0 , this._jsonService.status.message );
      this._navigationService.navigateTo(NavigationSegments.ERROR);
    }
  }

  ngAfterViewInit(): void {
    document.addEventListener('pointerdown' , this._onFirstClick );

    // Iniciamos saludo ( si no se ha hecho ya antes...)
    this.salutationRequest();
  }



  /**
   * Component is about to be destroyed
   */
  ngOnDestroy(): void {
    this._logger.ENABLED && this._logger.log('OnDestroy');

    this._subs.forEach(s => s.unsubscribe());
  }


  private onFirstClick(): void {
    this._audioFxService.unlock();

    document.removeEventListener('pointerdown' , this._onFirstClick );
  }










  private salutationRequest(): void {

    // Ya saludado !! directamente cargamos....
    if ( this.sessionStorageService.getItem('saluted') ){
      return;
    }

    // NO saludado aun!! abrimos dialogo de saludo
    const oDialogData: ConfirmDialogData = {
      title: this.languageService.translationGet('SALUTATION' , 'title' ),
      text: this.languageService.translationGet('SALUTATION' , 'text' ),
      accept: this.languageService.translationGet('SALUTATION' , 'accept' ),
    };

    const oConfig: MatDialogConfig = {
      closeOnNavigation: true,
      disableClose: true,
      autoFocus: true,
      data: oDialogData
    };
    const oRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent , oConfig);
    oRef.afterClosed().subscribe( this.salutationOnConfirmed.bind(this) );
  }



  public salutationOnConfirmed(accepted: boolean): void {

    if (accepted){
      // Recordamos visita
      this.sessionStorageService.setItem('saluted' , true);

      // Disparamos fulsscreen y nosleepp
      this.fullscreenService.activate();
      this.nosleepService.enable();
    }

  }






  scannerOpen(): void {

    const oConfig: MatDialogConfig = {
      closeOnNavigation: true,
      disableClose: true,
      autoFocus: true,
      data: this.scannedUrl
    };

    const oRef: MatDialogRef<ScannerDialogComponent> = this.dialog.open(ScannerDialogComponent , oConfig);
    oRef.afterClosed().subscribe( this.scannerOnResponse.bind(this) );
  }


  scannerOnResponse(oUrl: ScannedUrl): void {

    // Cancelled....
    if (!oUrl){
      return;
    }

    this.scannedUrl = oUrl;
    if (this.scannedUrl.valid){
      this._navigationService.navigateExternal(this.scannedUrl.url);
    }
  }



  shareOpen(): void {
    this._bottomSheet.open(ShareComponent);
  }


  favoriteOpen(): void {
    this._bottomSheet.open(FavoritesComponent);
  }





  @HostListener('window:resize', ['$event'])
  handleResize(event?: Event): void  {
    // document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
    document.body.style.height = `${window.innerHeight}px` ;
  }

}
