<div #container class="media-collection" >
  <video #video playsinline preload class="wh100PC" style="object-fit: contain;"
         [src]="selectedVideo?.url + '#t=0.001' "
         (playing)="isPlaying=true"
         (pause)="isPlaying=false"
         (ended)="isPlaying=false"
         (load)="videoOnEvent($event)"
         (canplay)="videoOnEvent($event)"
         (canplaythrough)="videoOnEvent($event)"
         (durationchange)="videoOnEvent($event)"
         (loadeddata)="videoOnEvent($event)"
         (loadedmetadata)="videoOnEvent($event)"
  ></video>
  <img class="btnPlay" src="./assets/icons/icon_play.svg" style="visibility: {{isPlaying ? 'hidden' : 'unset'}}" (click)="video.play()">
</div>


<!--overlay con la controladora de la galeria-->
<app-collection-controller [collection]="collection" (indexSelected)="collectionIndexOnChanged($event)" ></app-collection-controller>
