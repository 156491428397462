<div class='component-container'>

  <div class="middle-center">

    <h1 class='title'>Error</h1>

    <div class='content'>

      <div class='card'>
        <div *ngFor='let e of errorService.getAll()'>
          <div>{{errorService.getMessage(e)}}</div>
        </div>
      </div>

      <button mat-raised-button color="primary" (click)='exit()'>reload</button>

    </div>

  </div>


</div>

