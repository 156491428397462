/* tslint:disable:no-string-literal */
import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MediaCollectionComponent} from 'projects/visitor/src/app/components/media/media-collection/media-collection.component';
import {MediaCylinder, MediaCylinderHotspot} from 'projects/library/src/model/item-model';
import {TipService} from 'projects/visitor/src/app/services/tip/tip.service';
import {LanguageService} from 'projects/library/src/services/language/language.service';

@Component({
  selector: 'app-media-cylinder-collection',
  templateUrl: './media-cylinder-collection.component.html',
  styleUrls: ['./media-cylinder-collection.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MediaCylinderCollectionComponent extends MediaCollectionComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('cylinderDivRef')
  divRef: ElementRef;


  public selectedCylinder: MediaCylinder;

  public cylinderImage: HTMLImageElement;
  private imgW: number = 0;
  private imgH: number = 0;

  private div: HTMLDivElement;
  private divW: number = 0;
  private divH: number = 0;
  public divScale: number = 1;
  public frameX: number = 0;
  public frameY: number = 0;
  public frameRotation: number = 0;
  public frameWidth: number = 0;
  public frameHeight: number = 0;
  public frameRatio: number = 0;
  private frameCurrent: number = 0;

  private rotationSpeed: number = 20;
  private rotationStart: number = 0;
  private rotationActive: boolean = false;

  public cos: CallableFunction = Math.cos;

  constructor(
    public languageService: LanguageService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.div = this.divRef.nativeElement;
  }

  collectionIndexOnChanged(index: number): void {
    super.collectionIndexOnChanged(index);
    this.selectedCylinder = this.selectedItem as MediaCylinder;
  }



  imageOnload(img: HTMLImageElement): void {
    this.cylinderImage = img;
    this.imgW = this.cylinderImage.width;
    this.imgH = this.cylinderImage.height;
    this.frameWidth = this.imgW / this.selectedCylinder.frameAmount ;
    this.frameHeight = this.imgH;
    this.frameRatio = this.frameWidth / this.frameHeight ;
    this.divW = this.frameWidth ;
    this.divH = this.frameHeight;

    this.containerOnResized();

    // calculamos frame
    this.frameCurrent  = 0 ;
    this.rotationStart  = 0 ;
    this.rotationOnMove(0);
  }



  protected containerOnResized(entries?: any): void {
    super.containerOnResized(entries);

    let divNewW: number;
    let divNewH: number;
    let divNewS: number;


    // Determinar la proporcion del hueco y reescalar ancho y alto del DIV hasta que toque en algun lado.
    const containerRatio: number = this.container.offsetWidth / this.container.offsetHeight;

    // Container is more Landscape than div ?
    const isContainerMoreLandscape: boolean = containerRatio > this.frameRatio ;

    // Yes? fit Portrait
    if (isContainerMoreLandscape){
      divNewH = this.container.offsetHeight;
      divNewW = divNewH * this.frameRatio ;
    }
    // No? Fit Landscape
    else {
      divNewW = this.container.offsetWidth;
      divNewH = divNewW / this.frameRatio ;
    }

    // Get new Scale
    divNewS = divNewW / this.frameWidth;


    // En funcion de la escala, aplicar al DIV un background.size de X %
    this.divScale = divNewS;
  }



  mouseEvent(e: MouseEvent): void {
    const posX: number = e.offsetX;

    if (e.type === 'mousedown'){
      this.rotationOnStart(posX);
    }
    else if (e.type === 'mousemove'){
      this.rotationOnMove(posX);
    }
    else if (e.type === 'mouseup'){
      this.rotationOnEnd(posX);
    }
  }





  touchEvent(e: TouchEvent): void {

    // Only one touch is allowed
    if (e.touches.length > 1){
      console.log('Only one touch is allowed');
      this.rotationOnEnd(0);
      return;
    }

    const oTouch: Touch = e.touches[0];

    const posX: number = oTouch.clientX;

    if (e.type === 'touchstart'){
      this.rotationOnStart(posX);
    }

    else if (e.type === 'touchmove'){
      this.rotationOnMove(posX);
    }
    else if (e.type === 'touchend'){
      this.rotationOnEnd(posX);
    }
    else if (e.type === 'touchcancel'){
      this.rotationOnEnd(posX);
    }
  }


  rotationOnStart(x: number): void {
    this.rotationActive = true;
    this.rotationStart = x;
  }
  rotationOnEnd(x: number): void {
    this.rotationActive = false;
  }

  rotationOnMove(x: number): void {
    if (!this.rotationActive){
      return;
    }
    const direction: number = x > this.rotationStart ? 1 : -1 ;
    const delta: number = Math.abs(x - this.rotationStart) ;
    const numFrames: number = Math.floor(delta / this.rotationSpeed );

    if (numFrames > 0){
      this.rotationStart += this.rotationSpeed * direction ;
    }

    let nextRot: number = this.frameCurrent + (numFrames * direction ) ;
    if (nextRot > this.selectedCylinder.frameAmount){
      nextRot = nextRot % this.selectedCylinder.frameAmount ;
    }
    else if (nextRot < 0){
      nextRot = this.selectedCylinder.frameAmount + nextRot ;
    }
    this.frameCurrent = nextRot ;

    this.frameX =  this.frameCurrent  * this.frameWidth ;
    this.frameRotation = this.frameCurrent / this.selectedCylinder.frameAmount * 360 % 360;

    // console.log(this.frameRotation);
  }


  public hotspotGetStyle(h: MediaCylinderHotspot): any {
    const oStyle = {};

    const currAng: number = (this.frameRotation + h.angle ) % 360 ;
    const toRad: number = 3.141598 / 180 ;
    const x: number = 50 + h.x * Math.cos( (currAng - 90) * toRad );
    const y: number = h.y;
    const alpha: number = 1 + Math.cos(currAng * toRad );
    // const transform: string = 'scale(' + 1 / this.divScale + ')';
    const lab: string = this.languageService.translate( h ) ;


    oStyle['left.%'] = x;
    oStyle['bottom.%'] = y ;
    oStyle['opacity'] =  alpha;
    // oStyle['transform'] = transform;
    // console.log( this.frameRotation ,  lab , currAng , alpha );

    return oStyle;
  }



  public hotspotClick(div: HTMLDivElement , h: MediaCylinderHotspot): void {
    h.opened = true; // div.style.opacity > '0.3' ;
  }

  public hotspotReleaseAll(): void {
    for (const h of this.selectedCylinder.hotSpots){
      h.opened = false;
    }
  }


  @HostListener('window:mousedown', ['$event'])
  handleMouseDown(event: KeyboardEvent): void  {
    this.hotspotReleaseAll();
  }

  @HostListener('window:touchdown', ['$event'])
  handleTouchDown(event: TouchEvent): void  {
    this.hotspotReleaseAll();
  }

  @HostListener('window:mouseup', ['$event'])
  handleMouseUp(event: KeyboardEvent): void  {
    this.rotationOnEnd(0);
  }

  @HostListener('window:touchup', ['$event'])
  handleTouchUp(event: TouchEvent): void  {
    this.rotationOnEnd(0);
  }


}
