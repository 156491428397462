<div class="header">
  <h2 mat-dialog-title class="title">{{message}}</h2>
  <mat-icon (click)="cameraToggle()" color="primary">flip_camera_ios</mat-icon>
</div>

<mat-dialog-content>

  <div #canvasContainerRef class="stream" [class.valid]="scannedUrl?.valid">
    <!--video canvas stream to be inserted here-->
  </div>

  <!-- texto con la url detectada -->
  <div class="href" [class.valid]="scannedUrl?.valid">{{scannedUrl?.url}}</div>

</mat-dialog-content>


<mat-dialog-actions align="end">
  <button mat-raised-button cdkFocusInitial [mat-dialog-close]="null" >{{languageService.translationGet('SCANNER' , 'cancel' )}}</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="scannedUrl" cdkFocusInitial [disabled]="!scannedUrl?.valid" >{{languageService.translationGet('SCANNER' , 'accept' )}}</button>
</mat-dialog-actions>
