import {LoggerInterface} from '../logger.interface';
import {LoggerLevelEnum} from '../logger.level.enum';
import {getMySqlDate} from 'projects/library/src/util/date/date-util';

export class LoggerImplementationConsole implements LoggerInterface {

  private readonly handlers: CallableFunction[];

  constructor(
    public title: string = 'Logger',
    public level: number = 0,
    public ENABLED: boolean = true
  ){

    this.handlers = [];
    this.handlers[0] = console.log ;
    // tslint:disable-next-line:no-console
    this.handlers[1] = console.debug ;
    // tslint:disable-next-line:no-console
    this.handlers[2] = console.info ;
    // tslint:disable-next-line:no-console
    this.handlers[3] = console.warn ;
    // tslint:disable-next-line:no-console
    this.handlers[4] = console.error ;

  }




  private trace(lv: number  ,  ...args: any[]): void {
    if (this.level > lv ){ return; }

    const sDate: string = getMySqlDate() ;
    const sLevel: string = '  ' + String(LoggerLevelEnum[lv]).padEnd(5 , ' ');
    const sTitle: string = '  ' +  `[${ this.title }]`.padEnd(25 , ' ') ;
    const arrMessage: any[] = [sDate , sLevel , sTitle].concat(...args) ;

    // MUestra el correcto handler (log -> log , warn -> warn ...)
    // Pero dentro de Websitorm, me despliega mucho detalle
    // this.handlers[lv]( ...arrMessage );

    // De momento, lo sustituypo temporalmente por info
    // tslint:disable-next-line:no-console
    console.info( ...arrMessage);


  }



  log(...args: any[]): void {
    this.trace(0 , args);
  }

  debug(...args: any[]): void {
    this.trace(1 , args);
  }

  info(...args: any[]): void {
    this.trace(2 , args);
  }


  warn(...args: any[]): void {
    this.trace(3 , args);
  }

  error(...args: any[]): void {
    this.trace(4 , args);
  }



}
