/*
https://hiukim.github.io/mind-ar-js-doc/tools/compile
*/
import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {MediaCollectionComponent} from 'projects/visitor/src/app/components/media/media-collection/media-collection.component';
import {MediaAr, MediaGigapixel} from 'projects/library/src/model/item-model';
import {DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-media-ar-collection',
  templateUrl: './media-ar-collection.component.html',
  styleUrls: ['./media-ar-collection.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MediaArCollectionComponent extends MediaCollectionComponent implements OnInit, OnDestroy, AfterViewInit {

  public selectedAR: MediaAr ;
  public frameSrc: string;


  constructor(
    // private sanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  collectionIndexOnChanged(index: number): void {
    super.collectionIndexOnChanged(index);
    this.selectedAR = this.selectedItem as MediaAr;

    // this.frameSrc = 'data:text/html;charset=utf-8,' + this.getFrameSrc();
    this.frameSrc = 'https://client.prosodik.com/ar/ar.html';
  }

  private getFrameSrc(): string {

    const html: string = `
      <html>
        <head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <script src="https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.0.0/dist/mindar-image.prod.js"></script>
          <script src="https://aframe.io/releases/1.2.0/aframe.min.js"></script>
          <script src="https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.0.0/dist/mindar-image-aframe.prod.js"></script>
        </head>
        <body>
          <a-scene mindar-image="imageTargetSrc: https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.0.0/examples/image-tracking/assets/card-example/card.mind;" color-space="sRGB" renderer="colorManagement: true, physicallyCorrectLights" vr-mode-ui="enabled: false" device-orientation-permission-ui="enabled: false">
            <a-assets>
          <img id="card" src="https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.0.0/examples/image-tracking/assets/card-example/card.png" />
          <a-asset-item id="avatarModel" src="https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.0.0/examples/image-tracking/assets/card-example/softmind/scene.gltf"></a-asset-item>
            </a-assets>

            <a-camera position="0 0 0" look-controls="enabled: false"></a-camera>

            <a-entity mindar-image-target="targetIndex: 0">
              <a-plane src="#card" position="0 0 0" height="0.552" width="1" rotation="0 0 0"></a-plane>
              <a-gltf-model rotation="0 0 0 " position="0 0 0.1" scale="0.005 0.005 0.005" src="#avatarModel"
                animation="property: position; to: 0 0.1 0.1; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate"
              >
            </a-entity>
          </a-scene>
        </body>
      </html>`;

    // const src: string = 'data:text/html;charset=utf-8,' + encodeURI(html);
    const src: string = encodeURI(html);

    return src;
  }


}
