/* tslint:disable:max-line-length */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, ElementRef, HostListener,
  OnDestroy,
  OnInit, ViewChild,
} from '@angular/core';
import {MediaCollectionComponent} from 'projects/visitor/src/app/components/media/media-collection/media-collection.component';
import { MediaModel, MediaModelHotspot} from 'projects/library/src/model/item-model';
import {LanguageService} from 'projects/library/src/services/language/language.service';

@Component({
  selector: 'app-media-model-collection',
  templateUrl: './media-model-collection.component.html',
  styleUrls: ['./media-model-collection.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MediaModelCollectionComponent extends MediaCollectionComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('viewerRef')
  viewerRef: ElementRef;
  protected viewer: any;

  public selectedModel: MediaModel;
  public hotSpots: MediaModelHotspot[] = [];

  public animationList: any[] = [];
  // public animationName: string = null;


  constructor(
    public languageService: LanguageService,
  ) {
    super();
    this.loadScript('./assets/scripts/model-viewer/model-viewer.min.js' , true );
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }


  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.viewer = this.viewerRef.nativeElement;
  }


  collectionIndexOnChanged(index: number): void {
    super.collectionIndexOnChanged(index);
    this.selectedModel = this.selectedItem as MediaModel;


    if (this.selectedModel.hotSpots){
      const arrHotspots: MediaModelHotspot[] = JSON.parse(JSON.stringify(this.selectedModel.hotSpots));
      this.hotSpots = arrHotspots ;
    }
  }


  public modelOnLoad(): void {
    this.animationsDoFind();
  }

  private animationsDoFind(): void {
    if (this.viewer?.availableAnimations){
      this.animationList = this.viewer.availableAnimations;
    }
  }




  animationPlay(sAnimationName: string): void {
    this.viewer.animationName = sAnimationName;
    this.viewer.play();
  }


  public hotspotClick(div: HTMLDivElement , h: MediaModelHotspot): void {
    h.opened = true; // div.style.opacity > '0.3' ;
  }

  public hotspotReleaseAll(): void {
    for (const h of this.hotSpots ){
      h.opened = false;
    }
  }


  @HostListener('window:mousedown', ['$event'])
  handleMouseDown(event: KeyboardEvent): void  {
    this.hotspotReleaseAll();
  }

  @HostListener('window:touchdown', ['$event'])
  handleTouchDown(event: TouchEvent): void  {
    this.hotspotReleaseAll();
  }
}
