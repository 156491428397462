import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(arr: any[], condition: any): any[] {
    return arr.filter( item => {
      for (const propName in condition){
        const conditionValue: any = condition[propName];
        const itemValue: any = item[propName];
        if(itemValue !== conditionValue ) return false;
      }
      return true;
    });
  }

}
