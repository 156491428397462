import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {MediaCollectionComponent} from 'projects/visitor/src/app/components/media/media-collection/media-collection.component';
import {MediaVideo} from 'projects/library/src/model/item-model';


@Component({
  selector: 'app-media-video-collection',
  templateUrl: './media-video-collection.component.html',
  styleUrls: ['./media-video-collection.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MediaVideoCollectionComponent extends MediaCollectionComponent implements OnInit, OnDestroy, AfterViewInit {

  public selectedVideo: MediaVideo;
  public isPlaying: boolean = false;


  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  collectionIndexOnChanged(index: number): void {
    super.collectionIndexOnChanged(index);

    this.selectedVideo = this.selectedItem as MediaVideo;
    this.isPlaying = false;
  }

  videoOnEvent(e: Event): void {
    console.log('videoOnEvent' , e.type , JSON.stringify(e) );
  }
}
