import {InstanceFactory} from 'projects/library/src/util/class/factory/instance-factory';
import {AudioPlayerTypesEnum} from 'projects/library/src/audioPlayer/audio-player.types.enum';
import {AudioPlayerImplementationFile} from 'projects/library/src/audioPlayer/implementation/file/audio-player-implementation-file';
import {
  AudioPlayerImplementationSynthesis
} from 'projects/library/src/audioPlayer/implementation/synthesis/audio-player-implementation-synthesis';


export class AudioPlayerFactory extends InstanceFactory {

  static __constructor(): void {
    AudioPlayerFactory.__collection = AudioPlayerFactory.__collectionGet();

    // default
    AudioPlayerFactory.classAdd(AudioPlayerTypesEnum.FILE , AudioPlayerImplementationFile );
    AudioPlayerFactory.classAdd(AudioPlayerTypesEnum.SYNTHESIS , AudioPlayerImplementationSynthesis );
  }

  constructor(){
    super();
  }
}

// Note that the static constructor must be called immediately
AudioPlayerFactory.__constructor();
