import {IAudioPlayer, IAudioSyncMetadata} from 'projects/library/src/audioPlayer/implementation/i-audio-player';
import {EventEmitter} from '@angular/core';
import {ILanguage} from 'projects/library/src/services/language/i-language';
import {AudioItemFile, AudioItemSynthesis} from 'projects/library/src/model/item-model';
import {asyncTimeout} from 'projects/library/src/util/async/timeout-async';
import gsap from 'gsap';

// @ts-ignore
import SimplexNoise from 'simplex-noise';
import {BehaviorSubject, Subject} from 'rxjs';

export class AudioPlayerImplementationSynthesis implements IAudioPlayer{

  public complete$: EventEmitter<boolean>;
  public loaded$: EventEmitter<boolean>;
  public position$: EventEmitter<number>;
  public error$: EventEmitter<string>;
  public metadata$: Subject<IAudioSyncMetadata>;
  public spectrum$: EventEmitter<number[]>;

  private _loaded: boolean;
  private _onTimeUpdate: any;
  private _onTimeEnded: any;
  private _onPlaying: any;
  private _onResume: any;
  private _onPaused: any;
  private _onError: any;
  private _onCanPlayThrough: any;

  private _playing: boolean = false ;
  private _paused: boolean = false;
  private _afInterval: number;
  private _loop: boolean = false;


  private synth: SpeechSynthesis;
  private voices: SpeechSynthesisVoice[];
  private voiceSelected: SpeechSynthesisVoice;
  private _spectrum: number[];
  private _noiser  = new SimplexNoise();
  private _noiseCounter: number  = 0 ;


  constructor(
    private _text: string,
    private _lang: ILanguage,
    private descriptor: AudioItemSynthesis
  ) {
    this.position$ = new EventEmitter<number>();
    this.complete$ = new EventEmitter<boolean>();
    this.loaded$ = new EventEmitter<boolean>();
    this.error$ = new EventEmitter<string>();
    this.metadata$ = new Subject<IAudioSyncMetadata>();
    this.spectrum$ = new EventEmitter<number[]>();


    this._onTimeUpdate = this.onTimeUpdate.bind(this);
    this._onTimeEnded = this.onTimeEnded.bind(this);
    this._onPlaying = this.onPlaying.bind(this);
    this._onResume = this.onResume.bind(this);
    this._onPaused = this.onPaused.bind(this);
    this._onError = this.onError.bind(this);
    this._onCanPlayThrough = this.onCanPlayThrough.bind(this);


    this.synth = speechSynthesis || window.speechSynthesis;

    this._loaded = false;
    this.load();
  }


  private async load(): Promise<void> {

    // 1st time
    if (this._loaded){
      return ;
    }

    // Cargamos voces....
    this.loadVoices();
    if (this.synth.onvoiceschanged !== undefined) {
      this.synth.onvoiceschanged = this.loadVoices.bind(this) ;
    }

    // Pero tardan en entrar..... Por eso hago este bucle cutre...
    while (!this.voices || this.voices.length === 0){
      await asyncTimeout(500);
      this.loadVoices();
    }

    // Ya tenemos voces !!
    // Buscamos las voces del mismo idioma
    const langDeviceCode: string = this._lang.code.toLowerCase();
    const sameLangVoices: SpeechSynthesisVoice[] = this.voices.filter(v => v.lang.toLowerCase().indexOf(langDeviceCode) === 0 );
    if (sameLangVoices.length === 0 ){
      alert('tu telefono no contiene ninguna voz en el idioma: ' + this._lang.title);
      this.clear();
      return;
    }

    // Ahora buscamos la voz nativa
    this.voiceSelected = sameLangVoices.find(v => v.localService && v.default );

    // NO hay nativa?, pues entonces la 1º identica al locale
    !this.voiceSelected ? this.voiceSelected = sameLangVoices.find(v => v.lang === this._lang.locale ) : void(0);

    // O la 1º de la lista
    !this.voiceSelected ? this.voiceSelected = sameLangVoices[0] : void(0);



    this._loaded = true;
    this.loaded$.emit(this._loaded);
  }


  /*
  private async measureDuration(speed: number): Promise<number>{
    return new Promise((resolve, reject) => {

      const oUtter: SpeechSynthesisUtterance = new SpeechSynthesisUtterance(this._text);
      oUtter.rate = speed;
      oUtter.volume = 0;
      oUtter.onend = (e: SpeechSynthesisEvent) => {
        oUtter.onend = null;
        oUtter.volume = 1;
        resolve(e.elapsedTime * speed);
      };
      this.synth.speak(oUtter);
    });
  }
  */





  public getVoices(): SpeechSynthesisVoice[] {
    return this.synth.getVoices().sort( (a, b) => {
      const aname = a.name.toUpperCase();
      const bname = b.name.toUpperCase();

      if ( aname < bname ){
        return -1;
      } else if ( aname === bname ){
        return 0;
      } else {
        return +1;
      }
    });
  }

  public loadVoices(): void {
    this.voices = this.getVoices();
  }




  private onTimeUpdate(e: SpeechSynthesisEvent): void {
    // const position: number = e.elapsedTime;
    const position: number = e.charIndex;
    this.position$.emit(position);

    const word: string = e.utterance.text.substr(e.charIndex , e.charLength);
    const oMetadata: IAudioSyncMetadata = {
      time: e.elapsedTime,
      type: 'word',
      start: e.charIndex,
      end: e.charLength,
      value: word
    };
    this.metadata$.next(oMetadata);

    console.log('SpeechSynthesisUtterance.onTimeUpdate' , position , oMetadata.value  );
  }

  private setPlaying(b: boolean): void {
    this._playing = b;
    if (this._playing){
      this.getSpectrum();
    }
  }

  private setPaused(b: boolean): void {
    this._paused = b;
  }



  private onTimeEnded(e: SpeechSynthesisEvent): void {
    this.setPlaying(false);
    this.setPaused(false);
    console.log('SpeechSynthesisUtterance.onend');
    this.complete$.emit(true);

    if (this._loop){
      this.play(0);
    }
  }

  private onPlaying(e: SpeechSynthesisEvent): void {
    this.setPlaying(true);
    this.setPaused(false);
    console.log('SpeechSynthesisUtterance.onPlaying ');
  }
  private onResume(e: SpeechSynthesisEvent): void {
    this.setPlaying(true);
    this.setPaused(false);
    console.log('SpeechSynthesisUtterance.onResume ');
  }

  private onPaused(e: SpeechSynthesisEvent): void {
    this.setPlaying(false);
    this.setPaused(true);
    console.log('SpeechSynthesisUtterance.onPaused');
  }

  private onError(e: ErrorEvent): void {
    this.setPlaying(false);
    this.setPaused(false);
    console.log('SpeechSynthesisUtterance.onError' , e.error );
    this.error$.emit(e.error);
  }

  private onCanPlayThrough(): void {
    console.log('SpeechSynthesisUtterance.onCanPlayThrough');
    this.loaded$.emit(true);
  }











  public getDuration(): number {
    return this._text.length;
    // return this.duration;
  }

  public pause(): void {
    try{
      this.setPlaying(false);
      this.setPaused(true);
      this.synth.pause();
    }catch (e) {
      console.log('Pause Error' , e);
    }
  }

  public setLoop(b: boolean): void {
    this._loop = b;
  }

  public async play(ms: number = 0): Promise<void> {
    try{
      this.synth.cancel();
    }catch (e) {
      console.log('Cancel Error' , e);
    }

    return this._play();
  }



  private utterGet(text: string): SpeechSynthesisUtterance {
    const utter: SpeechSynthesisUtterance = new SpeechSynthesisUtterance(text);
    utter.voice = this.voiceSelected;
    utter.lang = this._lang.locale;
    utter.pitch = this.descriptor.pitch;
    utter.rate = this.descriptor.rate;
    utter.volume = 1;
    utter.onmark = (e: SpeechSynthesisEvent) => {
      console.log('utter.onmark');
    };

    // Medimos la duración
    // this.duration = await this.measureDuration(10);

    utter.onboundary = this._onTimeUpdate;
    utter.onend = this._onTimeEnded;
    utter.onstart = this._onPlaying;
    utter.onresume = this._onResume;
    utter.onpause = this._onPaused;
    utter.onerror = this._onError;
    return utter;
  }


  private async _play(): Promise<void> {

    await this.load();

    this.synth.resume();

    /*
    const arrSentences: string[] = this._text.split('.');
    for (const sentence of arrSentences){
      const utter: SpeechSynthesisUtterance = this.utterGet(sentence);
      this.synth.speak(utter);
    }
    */

    const utter: SpeechSynthesisUtterance = this.utterGet(this._text);
    this.synth.speak(utter);

    this.setPlaying(true);
  }


  public resume(): void {
    if (this._paused){
      this.synth.resume(); // speak(this.utter);
    } else {
      this.play();
    }
    // this._play();
  }

  public seek(ms: number): void {
    //
  }

  public clear(): void{
    cancelAnimationFrame(this._afInterval);
    this.synth.cancel();
  }

  public get playing(): boolean {
    return this._playing ;
    // this.synth.speaking;
  }









  private computeSpectrum(): void {
    this._spectrum = [];
    const amplitude: number = 255;
    for (let i: number = 0 ; i < 1024 ; i++){
      this._noiseCounter++;
      const c: number = this._noiseCounter / 255;
      const v: number = Math.floor( amplitude * this._noiser.noise2D(c , 1 ));
      this._spectrum.push(v);
    }

    this.spectrum$.emit(this._spectrum);
  }


  private getSpectrum(): void {

    this.computeSpectrum();

    if (this.playing){
      requestAnimationFrame( () => {
        this.getSpectrum();
      } );
    } else {
      this.fadeSpectrum();
    }
  }

  private fadeSpectrum(): void {

    let completed: boolean = true;
    for (let i = 0 ; i < this._spectrum.length ; i++){
      const v = this._spectrum[i];
      if (v > 0){
        this._spectrum[i]--;
        completed = false;
      }
      if (v < 0){
        this._spectrum[i]++;
        completed = false;
      }
    }

    if (completed){
      console.log('fade end');
      return;
    }


    console.log('fading');
    this.spectrum$.emit( [...this._spectrum] );

    this._afInterval = requestAnimationFrame( () => {
      this.fadeSpectrum();
    });
  }


  muteToggle(): void{
    //
  }
  public get muted(): boolean {
    return false ;
  }

}
