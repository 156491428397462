import { Injectable } from '@angular/core';
import { LoggerLevelEnum } from './logger.level.enum';
import { LoggerTypesEnum } from './logger.types.enum';
import { LoggerInterface } from './logger.interface';
import { LoggerFactory } from './logger-factory';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {




  /* STATIC CONFIG ********************************************************** */
  public static __config: any;

  // Used as default (before any config came)
  private static __configDefault: any = {
    type: 'console' ,
    level: 0
  };






  public static setConfig(d: any): void {
    LoggerService.__config = d;
  }

  public static getConfig(): any {
    return LoggerService.__config ;
  }







  public static getLogger(title: string , level: number = LoggerLevelEnum.LOG , type: string = LoggerTypesEnum.CONSOLE ): LoggerInterface {

    if (!LoggerService.__config){
      LoggerService.__config = LoggerService.__configDefault;
    }

    const c: any = LoggerService.__config;
    level = Math.max(level , c.level );
    type = (c.type ) ? c.type : type ;
    const oInstance: LoggerInterface = LoggerFactory.instanceGet( type , title , level, c.ENABLED ) ;
    return oInstance;
  }






  constructor() {
    //
  }



  public get config(): any {
    return LoggerService.getConfig() ;
  }



  public  getLogger(title: string , level: number = LoggerLevelEnum.LOG , type: string = LoggerTypesEnum.CONSOLE  ): LoggerInterface {
    return LoggerService.getLogger(title , level , type );
  }



}
