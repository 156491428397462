/* eslint-disable @typescript-eslint/no-inferrable-types */
import {Router} from '@angular/router';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {catchError, timeout} from 'rxjs/operators';
import {Observable , of} from 'rxjs';
import {Injectable} from '@angular/core';
import {LoggerInterface} from 'projects/library/src/services/logger/logger.interface';
import {LoggerService} from 'projects/library/src/services/logger/logger.service';
import {HttpLoadMode} from 'projects/library/src/services/http-loader/http-load-mode';




@Injectable({
  providedIn: 'root'
})
export class HttpLoaderService {

  // Timeout & cache settings for API calls
  public static HTTP_TIMEOUT: number = 20000;





  // logger
  protected logger!: LoggerInterface;


  constructor(
    private router: Router,
    private http: HttpClient,
    private loggerService: LoggerService
  ){
    this.logger = this.loggerService.getLogger('LoaderService');
  }





  /* **************************************************** LOAD  **************************************************** */


  public httpLoad<T>(
    url: string,
    mode: string = HttpLoadMode.GET,
    oBody: any = null,
    oParams: any = null,
    maxTimeout: number = HttpLoaderService.HTTP_TIMEOUT
  ): Observable<T> {

    this.logger.ENABLED && this.logger.log('http Load : ' +  url );

    let oResponse: Observable<unknown>;

    // Headers
    const oHeaders: HttpHeaders = new HttpHeaders();
    oHeaders.set('Cache-Control' , 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0');
    oHeaders.set('Pragma' , 'no-cache');
    oHeaders.set('Expires' , '0');
    oHeaders.set('Access-Control-Allow-Origin' , '*');

    const oOptions: any = {
      headers: oHeaders,
      params: oParams
    };

    // Mode ....
    if ( mode === HttpLoadMode.GET ){
      oResponse = this.http.get(url , oOptions) ;
    }
    else if ( mode === HttpLoadMode.POST ){
      oResponse = this.http.post(url , oBody , oOptions);
    }
    else if ( mode === HttpLoadMode.DELETE ){
      oResponse = this.http.delete(url , oOptions);
    }
    else if ( mode === HttpLoadMode.HEAD ){
      oResponse = this.http.head(url , oOptions);
    }
    else if ( mode === HttpLoadMode.PUT ){
      oResponse = this.http.put(url , oBody , oOptions);
    }
    else {
      return of(null);
    }


    // check response errors.....
    return oResponse.pipe(
      timeout(maxTimeout) ,
      catchError((e: HttpErrorResponse) => {
        this.logger.ENABLED && this.logger.error(url , mode , ' :: ' ,  e.error , e.message , e.status , e.url  );
        return of(null);
      }),
    ) as Observable<T> ;

  }



}
