import { LoggerTypesEnum } from './logger.types.enum';
import { LoggerImplementationConsole } from './implementation/logger.implementation.console';
import {InstanceFactory} from 'projects/library/src/util/class/factory/instance-factory';


export class LoggerFactory extends InstanceFactory {

  static __constructor(): void {
    LoggerFactory.__collection = LoggerFactory.__collectionGet();

    // default
    LoggerFactory.classAdd(LoggerTypesEnum.CONSOLE , LoggerImplementationConsole );
  }

  constructor(){
    super();
  }
}

// Note that the static constructor must be called immediately
LoggerFactory.__constructor();
