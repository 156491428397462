import {AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MediaItemCollection} from 'projects/library/src/model/item-model';

@Component({
  selector: 'app-collection-controller',
  templateUrl: './collection-controller.component.html',
  styleUrls: ['./collection-controller.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CollectionControllerComponent implements OnInit, OnDestroy, AfterViewInit {


  private _collection: MediaItemCollection<any>;

  @Input()
  set collection(c: MediaItemCollection<any>) {
    this._collection = c;
    setTimeout( () => {
      this.moveIndex(0);
    } , 20);
  }
  get collection(): MediaItemCollection<any> {
    return this._collection;
  }


  @Output()
  indexSelected: EventEmitter<number> = new EventEmitter<number>();


  public index: number;


  constructor() {
    this.index = 0;
  }


  ngOnInit(): void {
    //
  }

  ngOnDestroy(): void {
    //
  }

  ngAfterViewInit(): void {
    //
  }


  isEmpty(): boolean {
    return this.collection.items.length === 0 ;
  }

  hasSiblings(): boolean {
    return this.collection.items.length > 1 ;
  }

  hasNext(): boolean {
    return this.index < this.collection.items.length - 1;
  }

  hasPrev(): boolean {
    return this.index > 0 ;
  }


  next(): void {
    if (!this.hasNext()){
      return;
    }
    this.moveIndex(1);
  }

  prev(): void {
    if (!this.hasPrev()){
      return;
    }
    this.moveIndex(-1);
  }

  private moveIndex(n: number): void {
    const newIndex: number = this.index + n ;
    this.gotoIndex(newIndex);
/*
    this.index += n;
    this.indexSelected.emit(this.index);
*/
  }

  gotoIndex(n: number): void {
    this.index = n;
    this.indexSelected.emit(this.index);
  }

}
