import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {LoggerInterface} from 'projects/library/src/services/logger/logger.interface';
import {NavigationService} from 'projects/library/src/services/navigation/navigation.service';
import {LoggerService} from 'projects/library/src/services/logger/logger.service';
import {ErrorService} from 'projects/library/src/services/error/error.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements  OnInit, OnDestroy {

  private _logger: LoggerInterface;
  private _subs: Subscription[];
  private _timeouts: number[];

  constructor(
    public errorService: ErrorService,
    private _navigationService: NavigationService
  ) {
    this._logger = LoggerService.getLogger('Error Component');
    this._subs = [];
    this._timeouts = [];
  }

  ngOnInit(): void {
    this._logger.ENABLED && this._logger.log('OnInit');
  }


  /**
   * Component is about to be destroyed
   */
  ngOnDestroy(): void{
    this._logger.ENABLED && this._logger.log('OnDestroy');
    this._subs.forEach(s => s.unsubscribe());
    this._timeouts.forEach(n => window.clearTimeout(n) );
  }


  public exit(): void {
    this._navigationService.reload();
  }

}
