<div #container class="media-collection" >
  <img #image class="media-image" [src]="selectedLocation?.url">
  <img
    src="./assets/icons/location.svg"
    style="
    width: 30px;
    height: 45px;
    position: absolute;
    left:{{image.offsetLeft + image.offsetWidth * selectedLocation?.position.x}}px;
    top:{{image.offsetTop + image.offsetHeight * selectedLocation?.position.y}}px;
    transform: translate(-50% , -100%);
    color:red;
    "
  >

</div>

<!--overlay con la controladora de la galeria-->
<app-collection-controller [collection]="collection" (indexSelected)="collectionIndexOnChanged($event)" ></app-collection-controller>
