/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Injectable } from '@angular/core';


interface ErrorDefinition {
  code: number;
  // concept: string,
  message?: string;
}


@Injectable({
  providedIn: 'root'
})
export class ErrorService {


  public static TIMEOUT: number = 701;
  public static NETWORK: number = 702;
  public static FILE_NOT_FOUND: number = 703;
  public static FILE_MALFORMED: number = 704;
  public static FILE_NOT_PARSED: number = 705;
  public static LOCATION_NOT_FOUND: number = 706;
  public static POSITION_NOT_FOUND: number = 707;
  public static SPECTACLE_NOT_FOUND: number = 708;
  public static ASSET: number = 709;
  public static CLIENT: number = 710;
  public static LATENCY: number = 711;
  public static LOCATION: number = 712;
  public static PUBSUB: number = 713;



  private _concepts: string[];
  private _customErrors: ErrorDefinition[];


  constructor(
    //
  ) {
    this._concepts = [];
    this._concepts[701] = 'timeout' ;
    this._concepts[702] = 'network' ;
    this._concepts[703] = 'file_not_found' ;
    this._concepts[704] = 'file_malformed' ;
    this._concepts[705] = 'file_not_parsed' ;
    this._concepts[706] = 'location_not_found' ;
    this._concepts[707] = 'position_not_number' ;
    this._concepts[708] = 'spectacle_not_found' ;
    this._concepts[709] = 'asset' ;
    this._concepts[710] = 'client' ;
    this._concepts[711] = 'latency' ;
    this._concepts[712] = 'location' ;
    this._concepts[713] = 'pubsub' ;


    this._customErrors = [];
  }



  public add(code?: number , message?: string ): void {
    const newError: ErrorDefinition  = {
      code,
      // concept: this.getConcept(code),
      message
    };
    this._customErrors.push(newError);
  }

  public getConcept(code: number): string {
    return this._concepts[code];
  }

  public getAll(): ErrorDefinition[] {
    return this._customErrors;
  }

  public getLast(): ErrorDefinition {
    return this._customErrors[this._customErrors.length - 1];
  }



  public getMessage(e: ErrorDefinition): string{
    if (e.message){
      return e.message;
    }

    return e.code.toString();
  }






}
