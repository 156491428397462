import {InstanceFactory} from 'projects/library/src/util/class/factory/instance-factory';
import {AudioPlayerTypesEnum} from 'projects/library/src/audioPlayer/audio-player.types.enum';
import {AudioPlayerImplementationFile} from 'projects/library/src/audioPlayer/implementation/file/audio-player-implementation-file';
import {
  AudioPlayerImplementationSynthesis
} from 'projects/library/src/audioPlayer/implementation/synthesis/audio-player-implementation-synthesis';
import {SubtitleParserTypesEnum} from 'projects/library/src/subtitles/parser/subtitle-parser-types.enum';
import {SubtitleParserMarks} from 'projects/library/src/subtitles/parser/implementation/subtitle-parser-marks';
import {SubtitleParserVtt} from 'projects/library/src/subtitles/parser/implementation/subtitle-parser-vtt';


export class SubtitleParserFactory extends InstanceFactory {

  static __constructor(): void {
    SubtitleParserFactory.__collection = SubtitleParserFactory.__collectionGet();

    // default
    SubtitleParserFactory.classAdd(SubtitleParserTypesEnum.MARKS , SubtitleParserMarks );
    SubtitleParserFactory.classAdd(SubtitleParserTypesEnum.VTT , SubtitleParserVtt );
  }

  constructor(){
    super();
  }
}

// Note that the static constructor must be called immediately
SubtitleParserFactory.__constructor();
