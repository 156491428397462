import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {LoggerInterface} from 'projects/library/src/services/logger/logger.interface';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {LoggerService} from 'projects/library/src/services/logger/logger.service';
import {ItemService} from 'projects/library/src/services/item/item.service';
import {ErrorService} from 'projects/library/src/services/error/error.service';
import {NavigationService} from 'projects/library/src/services/navigation/navigation.service';
import {AudioItemDescriptor, InfoItem, ItemModel, MediaItem, MediaItemCollection} from 'projects/library/src/model/item-model';
import {NavigationSegments} from 'projects/visitor/src/app/navigation/navigation-segments.enum';
import {LanguageService} from 'projects/library/src/services/language/language.service';
import {AudioPlayer} from 'projects/library/src/audioPlayer/audio-player';
import {MatTabChangeEvent} from '@angular/material/tabs';

import {ItemMediaType} from 'projects/library/src/model/item-media-type.enum';
import {ItemInfoType} from 'projects/library/src/model/item-info-type.enum';
import {ILanguage} from 'projects/library/src/services/language/i-language';
import {TipService} from 'projects/visitor/src/app/services/tip/tip.service';
import {opacityAnimation} from 'projects/visitor/src/app/animations/animations';
import {ItemSync} from 'projects/library/src/model/item-sync';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogData} from 'projects/library/src/model/confirm-dialog-data';
import {ConfirmDialogComponent} from 'projects/visitor/src/app/components/dialogs/confirm-dialog/confirm-dialog.component';
import {LatencyInterface, LatencyService} from 'projects/library/src/services/latency/latency.service';


@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
  animations: [
    opacityAnimation
  ]
})
export class ItemComponent implements OnInit, OnDestroy {

  private _logger: LoggerInterface;
  private _subs: Subscription[];
  protected _timeouts: number[];

  public audioPlayer: AudioPlayer;

  public item: ItemModel;
  public itemID: string;
  public itemText: InfoItem;
  public ItemMediaType = ItemMediaType;
  public ItemInfoType = ItemInfoType;

  public mediaCollectionSelected: MediaItemCollection<MediaItem>;
  public mediaItemSelectedNull: MediaItem;
  public mediaItemSelected: MediaItem;
  public infoSelected: InfoItem;

  public tip: string = '';
  public iconsByMedia: any;
  public playSync: boolean = false;
  public syncData: ItemSync;



  constructor(
    private _route: ActivatedRoute,
    public itemService: ItemService,
    private _errorService: ErrorService,
    private _navigationService: NavigationService,
    public languageService: LanguageService,
    public latencyService: LatencyService,
    public tipService: TipService,
    public dialog: MatDialog
) {
    this._logger = LoggerService.getLogger('Item  Component');
    this._subs = [];
    this._timeouts = [];

    this.item = {
      itemID: null,
      tag: null,
      locationID: null,
      media: [],
      information: [],
      languages: [],
      audio: null,
      sync: false,
      icon: null
    };



    this.audioPlayer = new AudioPlayer();

    this.mediaItemSelectedNull = {
      url: '',
      glossaryCollection: null
    };
    this.mediaCollectionSelected = {
      type: '',
      items: []
    };

    this.iconsByMedia = {
      spectrum: 'graphic_eq',
      model: 'view_in_ar',
      cylinder: 'panorama_horizontal',
      spherical: 'panorama_photosphere',
      compare: 'compare',
      image: 'image',
      video: 'slideshow',
      gigapixel: 'zoom_out',
      location: 'place',
      ar: 'view_in_ar'
    };
  }





  ngOnInit(): void {
    this._logger.ENABLED && this._logger.log('OnInit');

    this.itemID = this._route.snapshot.paramMap.get('id');
    this._logger.log('ITEM: ' + this.itemID );


    // Escuchamos ....
    this.itemListen();

    // cargamos datos
    this.itemLoad();
  }

  /**
   * Component is about to be destroyed
   */
  ngOnDestroy(): void{
    this._logger.ENABLED && this._logger.log('OnDestroy');

    this._subs.forEach(s => s.unsubscribe());
    this._timeouts.forEach(n => window.clearTimeout(n) );
    this.audioPlayer.clear();
  }







  public itemListen(): void {
    this._subs.push(this.itemService.error$.subscribe(this.itemOnError.bind(this)));
    this._subs.push(this.itemService.item$.subscribe(this.itemOnComplete.bind(this)));
    this._subs.push(this.itemService.sync$.subscribe(this.syncOnData.bind(this) ) );
  }


  private itemLoad(): void {
    this.itemGetById(this.itemID);
  }


  public itemGetById(id: string): void {
    this.itemService.itemGetById(id);
  }

  private itemOnError(e: string ): void {
    this._logger.ENABLED && this._logger.log('OnError' , e );

    this._errorService.add(0 , e);
    this._navigationService.navigateTo(NavigationSegments.ERROR);
  }


  private itemOnComplete(oItem: ItemModel ): void{
    this._logger.ENABLED && this._logger.log('OnComplete' ); // JSON.stringify(oItem)

    this.item = oItem;
    this.itemText =  this.item.information.find(s => s.type === 'text' );

    this.itemDisplay(this.itemService.language);
  }

  itemDisplay(lang: ILanguage): void {

    const translatedText: string = this.languageService.translate(this.itemText , 'text' , this.itemService.language.code) ;
    const oAudioDescriptor: AudioItemDescriptor =  this.item.audio[this.itemService.language.code]  ;
    this.audioPlayer.setData( translatedText , this.itemService.language , oAudioDescriptor);

    // Set 1st media and text
    this.mediaIconOnChanged(this.item.media[0]);
    this.infoOnChanged(this.item.information[0]);


    // Sync?
    if (this.item.sync){
      this.syncRequest();
    }
  }

  itemLanguageSwap(lang: ILanguage ): void {
    this.itemService.languageSelect(lang);
    this.audioPlayer.pause();
    this.audioPlayer.clear();
    this.itemDisplay(lang);
  }







  syncRequest(): void {

    //
    this.latencyService.complete$.subscribe( (lat: LatencyInterface) => {
      console.log('this.latencyService.complete$' , lat);
      // 1st time? pass
      if (!lat){ return; }

      this.syncGetById();
    });

    // this.syncGetById();
  }

  syncGetById(): void {
    this.itemService.syncGetById(this.itemID);
  }


  syncOnData(oSync: ItemSync): void {
    this.syncData = oSync;
    this.syncDialogOpen();
  }

  syncDialogOpen(): void {

    const oDialogData: ConfirmDialogData = {
      title: this.languageService.translationGet('SYNC' , 'title' ),
      text: this.languageService.translationGet('SYNC' , 'text' ),
      accept: this.languageService.translationGet('SYNC' , 'accept' ),
      cancel: this.languageService.translationGet('SYNC' , 'cancel' ),
      callback: this.syncDialogOnResponse.bind(this)
    };

    const oConfig: MatDialogConfig = {
      closeOnNavigation: true,
      disableClose: true,
      autoFocus: true,
      data: oDialogData
    };

    const oRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent , oConfig);

    // NO me vale para reproducir el video porque la respuesta llega fuera del loop de la accion del boton.
    // Por eso usamos un callback inmediato.
    // oRef.afterClosed().subscribe( this.syncDialogOnResponse.bind(this) );
  }


  syncDialogOnResponse(accepted: boolean): void {
    this.syncPlay(accepted);
  }


  syncPlay(accepted: boolean): void {
    if (accepted){
      this.playSync = true;
      this.syncExecute();
    } else {
      this.playSync = false ;
      return;
    }
  }


  public syncExecute(): void {

    try{
      // Diferencia de tiempos
      const timeEmmited: number = this.syncData.time; // Hora absoluta NTP cuando se emitió el evento
      // const now: number = Date.now();                 // Hora absoluta ahora.
      const now: number = this.latencyService.ntpTime(); // Hora absoluta ahora.
      const elapsed: number = (now - timeEmmited);    // Diferencia de tiempos
      const position: number = (this.syncData.position + elapsed )  % this.syncData.duration ;  // Posicion del audio ahora

      this._logger.info('syncExecute' , this.syncData , timeEmmited , now , elapsed , position );

      // disparamos audio
      this.audioPlayer.play(position);
      this.audioPlayer.setLoop(true);
    }catch (e) {
      console.error(e);
      this.playSync = false;
    }
  }









  audioPlay(): void {
    this.audioPlayer.play();
  }

  audioPause(): void {
    this._logger.log('AudioPlayer.pause');
    this.audioPlayer.pause();
  }

  audioResume(): void {
    this._logger.log('AudioPlayer.resume');
    this.audioPlayer.resume();
  }

  audioPlaybackToggle(): void {
    this._logger.log('AudioPlayer.toggle');
    this.audioPlayer.playing ? this.audioPause() : this.audioResume() ;
  }

  audioSyncToggle(): void {
    this._logger.log('AudioPlayer.toggle Sync');
    this.audioPlayer.playing ? this.audioPause() : this.audioSync() ;
  }

  audioSync(): void {
    this.syncPlay(true);
  }


  audioPlaybackSeek(pc: number): void {

    if (this.playSync) {
      return;
    }

    this._logger.log('AudioPlayer.seek' , pc);
    this.audioPlayer.seek( pc * 1000 * this.audioPlayer.getDuration() ) ;
  }

  audioMuteToggle(): void {
    this.audioPlayer.muteToggle();
  }






  infoTabOnChanged(e: MatTabChangeEvent): void {
    this.infoOnChanged(this.item.information[e.index]);
  }

  infoOnChanged(m: InfoItem): void {
    this.infoSelected = m;
  }









  mediaIconOnChanged(m: MediaItemCollection<MediaItem>): void {
    this.mediaItemSelected = this.mediaItemSelectedNull;
    this.mediaCollectionSelected = m;
  }


  mediaCollectionOnChanged(item: MediaItem): void {
    this.mediaItemSelected = item;
  }


  mediaCollectionOntip(term: string): void {
    this.tip = this.tipService.get(term);
    this._timeouts.push(
      window.setTimeout( () => {
        this.tip = null;
      } , 10000 )
    );
  }


}
