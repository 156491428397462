export enum JsonCollectionName {

  // Comunes
  CONFIG = 'config',
  LANGUAGE = 'language',
  TRANSLATION = 'translation',
  COUNTRY = 'country',

  // asset loading
  ASSET = 'asset'

}
