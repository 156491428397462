import {InstantiableClass} from './instantiable-class';

export abstract class InstanceFactory {


  protected static __collection: Map<string , InstantiableClass>; // = new Map<string , InstantiableClass>();

  protected static __collectionGet(): Map<string , InstantiableClass> {
    return new Map<string , InstantiableClass>();
  }


  /*
  // Derived classes could call a static __constructor to ensure that the collection is prepopulated BEFORE the Factory instantiation
  protected static __constructor(): void {
     InstanceFactory.__collection = InstanceFactory.__collectionGet();
  }
  */


  public static classAdd(id: string , oClass: InstantiableClass ): void {
    this.__collection.set(id , oClass);
  }



  public static classDelete(id: string): void {
    this.__collection.delete(id);
  }



  public static classGet(id: string  ): InstantiableClass {
    return this.__collection.get(id) as InstantiableClass;
  }


  public static instanceGet (id: string , ...args: any[]  ): any {
    const oClass: any = this.classGet(id) ;
    return new oClass( ...args );
  }





  constructor(){
    //
  }



  public classAdd(id: string , oClass: InstantiableClass ): void {
    InstanceFactory.classAdd(id , oClass);
  }



  public classDelete(id: string): void {
    InstanceFactory.classDelete(id);
  }



  public classGet(id: string  ): InstantiableClass {
      return InstanceFactory.classGet(id);
  }



  public instanceGet<T>(id: string , ...args: any[]  ): T {
      const oClass: InstantiableClass = this.classGet(id);
      return new oClass(...args);
  }

}


// Derived classes could call a static __constructor to ensure that the collection is prepopulated BEFORE the Factory instantiation
// InstanceFactory.__constructor();
