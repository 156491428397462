import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {MediaCollectionComponent} from 'projects/visitor/src/app/components/media/media-collection/media-collection.component';
import {MediaCompare, MediaCompareMode} from 'projects/library/src/model/item-model';

@Component({
  selector: 'app-media-compare-collection',
  templateUrl: './media-compare-collection.component.html',
  styleUrls: ['./media-compare-collection.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MediaCompareCollectionComponent extends MediaCollectionComponent implements OnInit, OnDestroy, AfterViewInit {

  public selectedCompare: MediaCompare;
  public sliderValue: number = 50;


  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  collectionIndexOnChanged(index: number): void {
    super.collectionIndexOnChanged(index);
    this.selectedCompare = this.selectedItem as MediaCompare;
    this.sliderValue =  (this.selectedCompare?.mode === MediaCompareMode.SPLIT) ? 50 : 0 ;
  }


  public getStyle(): any{
    const oStyle: any = {};

    if (this.selectedCompare?.mode === MediaCompareMode.SPLIT){
      oStyle.clipPath =  `polygon(0 0 , ${this.sliderValue}% 0, ${this.sliderValue}% 100%, 0 100%)` ;
    }
    else if (this.selectedCompare?.mode === MediaCompareMode.FADE){
      oStyle.opacity = this.sliderValue / 100 ;
    }

    return oStyle;
  }
}
