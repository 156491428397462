import { Injectable } from '@angular/core';



@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  private _storage: Storage;

  constructor() {
    this._storage = sessionStorage;
  }

  public setItem<T>(key: string , value: T): void  {
    const jsonData = JSON.stringify(value);
    this._storage.setItem(key , jsonData);
  }

  public getItem<T>(key: string): T  {
    const jsonData: string = this._storage.getItem(key);
    const value: T = JSON.parse(jsonData);
    return value;
  }

  removeItem(key: string ): void  {
    this._storage.removeItem(key);
  }

  public clear(): void {
    this._storage.clear();
  }

}
