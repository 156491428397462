<mat-nav-list>

  <mat-list-item (click)="openLink('https://www.facefook.com/' )">
    <img mat-list-icon src="../../../assets/icons/social/facebook.svg">
    <span mat-line>Facebook</span>
  </mat-list-item>

  <mat-divider></mat-divider>


  <mat-list-item (click)="openLink('https://www.twitter.com/' )">
    <img mat-list-icon src="../../../assets/icons/social/twitter.svg">
    <span mat-line>Twitter</span>
  </mat-list-item>

  <mat-divider></mat-divider>



  <mat-list-item (click)="openLink('https://plus.google.com/' )">
    <img mat-list-icon src="../../../assets/icons/social/google.svg">
    <span mat-line>Google Plus</span>
  </mat-list-item>

  <mat-divider></mat-divider>


  <mat-list-item (click)="openLink('https://www.whatsapp.com/' )">
    <img mat-list-icon src="../../../assets/icons/social/whatsapp.svg">
    <span mat-line>WhatsApp</span>
  </mat-list-item>

  <mat-divider></mat-divider>


  <mat-list-item (click)="openLink('https://www.slack.com/' )">
    <img mat-list-icon src="../../../assets/icons/social/slack.svg">
    <span mat-line>Slack</span>
  </mat-list-item>



</mat-nav-list>
