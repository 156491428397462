<mat-accordion [multi]="false" >


  <mat-expansion-panel [expanded]="false" >
    <mat-expansion-panel-header>
      <mat-panel-title>Idioma de la interfaz</mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <mat-select [(ngModel)]="languageService.languageSelected" >
        <mat-option *ngFor="let l of languageService.languageCollection" [value]="l">
          {{l.title}}
        </mat-option>
      </mat-select>
    </div>
  </mat-expansion-panel>


  <mat-expansion-panel [expanded]="false" >
    <mat-expansion-panel-header>
      <mat-panel-title>Accesibilidad</mat-panel-title>
    </mat-expansion-panel-header>


    <mat-divider></mat-divider>

    <div style="margin-bottom: 20px; padding: 20px;">
      <h3>Párrafo</h3>
      <section>
        <mat-checkbox class="example-margin" [(ngModel)]="settingsService.settings.spell.sentenceActive" (ngModelChange)="settingsService.save()">activar</mat-checkbox>
      </section>
      <section>
        <mat-checkbox class="example-margin" [(ngModel)]="settingsService.settings.spell.sentenceScroll" (ngModelChange)="settingsService.save()">deslizar</mat-checkbox>
      </section>
      <section>
        <input type="color" [(ngModel)]="settingsService.settings.spell.sentenceColor" (ngModelChange)="settingsService.save()" >
        <mat-label>color</mat-label>
      </section>
    </div>

    <mat-divider></mat-divider>

    <div style="padding: 20px;">
      <h3>Palabra</h3>
      <section>
        <mat-checkbox class="example-margin" [(ngModel)]="settingsService.settings.spell.wordActive" (ngModelChange)="settingsService.save()">activar</mat-checkbox>
      </section>
      <section>
        <input type="color" [(ngModel)]="settingsService.settings.spell.wordColor" (ngModelChange)="settingsService.save()" >
        <mat-label>color</mat-label>
      </section>
    </div>

  </mat-expansion-panel>


  <mat-expansion-panel [expanded]="false" >
    <mat-expansion-panel-header>
      <mat-panel-title>Espectro</mat-panel-title>
    </mat-expansion-panel-header>

    <div>
      <mat-select [(ngModel)]="settingsService.settings.spectrum.mode" (ngModelChange)="settingsService.save()">
        <mat-option *ngFor="let m of settingsService.spectrumModes" [value]="m">
          {{m}}
        </mat-option>
      </mat-select>
    </div>


    <div style="margin-top: 20px;">
      <input type="color" [(ngModel)]="settingsService.settings.spectrum.colors[0]" (ngModelChange)="settingsService.save()" >
      <input type="color" [(ngModel)]="settingsService.settings.spectrum.colors[1]" (ngModelChange)="settingsService.save()" >
      <input type="color" [(ngModel)]="settingsService.settings.spectrum.colors[2]" (ngModelChange)="settingsService.save()" >
      <input type="color" [(ngModel)]="settingsService.settings.spectrum.colors[3]" (ngModelChange)="settingsService.save()" >
      <input type="color" [(ngModel)]="settingsService.settings.spectrum.colors[4]" (ngModelChange)="settingsService.save()" >
      <input type="color" [(ngModel)]="settingsService.settings.spectrum.colors[5]" (ngModelChange)="settingsService.save()" >
      <input type="color" [(ngModel)]="settingsService.settings.spectrum.colors[6]" (ngModelChange)="settingsService.save()" >
      <input type="color" [(ngModel)]="settingsService.settings.spectrum.colors[7]" (ngModelChange)="settingsService.save()" >
      <input type="color" [(ngModel)]="settingsService.settings.spectrum.colors[8]" (ngModelChange)="settingsService.save()" >
      <input type="color" [(ngModel)]="settingsService.settings.spectrum.colors[9]" (ngModelChange)="settingsService.save()" >
    </div>

  </mat-expansion-panel>

</mat-accordion>
