export enum ItemMediaType {
  SPECTRUM = 'spectrum',
  MODEL = 'model',
  CYLINDER = 'cylinder',
  SPHERICAL = 'spherical',
  GIGAPIXEL = 'gigapixel',
  COMPARE = 'compare',
  IMAGE = 'image',
  VIDEO = 'video',
  LOCATION = 'location',
  AR = 'ar',
}
