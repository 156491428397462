// https://cdn.jsdelivr.net/gh/foobar404/wave.js/dist/bundle.iife.js
import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MediaCollectionComponent} from 'projects/visitor/src/app/components/media/media-collection/media-collection.component';
import {AudioSpectrumItem} from 'projects/library/src/model/item-model';
import {SpectrumHelper} from 'projects/visitor/src/app/components/media/media-spectrum-visualizer/spectrum-helper';
import {Subject} from 'rxjs';
import {IAudioSyncMetadata} from 'projects/library/src/audioPlayer/implementation/i-audio-player';
import {SettingsService, SpectrumMode, SpellMode} from 'projects/visitor/src/app/services/settings/settings.service';
import {ILanguage} from 'projects/library/src/services/language/i-language';


@Component({
  selector: 'app-media-spectrum-visualizer',
  templateUrl: './media-spectrum-visualizer.component.html',
  styleUrls: ['./media-spectrum-visualizer.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MediaSpectrumVisualizerComponent extends MediaCollectionComponent implements OnInit, OnDestroy, AfterViewInit {



  public visualSpellMode: SpellMode = SpellMode.WORD;
  public visualSpellConcept: string = '';


  @Input()
  set spectrum(s: number[] ) {
    this.drawSpectrum(s);
  }

  @Input()
  metadata: Subject<IAudioSyncMetadata>;




  @ViewChild('canvas')
  canvasRef: ElementRef;
  protected canvas: HTMLCanvasElement;
  protected context: CanvasRenderingContext2D;


  protected drawer: any;
  protected drawerCollection: any;
  protected _spectrum: number[];
  protected _helper: SpectrumHelper;

  protected spectrumItem: AudioSpectrumItem;

  constructor(
    public settingsService: SettingsService,
  ) {
    super();


    this.drawerCollection = {};
    // this.drawerCollection[SpectrumMode.WAVE] = this.drawWave.bind(this);
    this.drawerCollection[SpectrumMode.FLOWER] = this.drawFlower.bind(this);
    this.drawerCollection[SpectrumMode.BARS] = this.drawBars.bind(this);
    this.drawerCollection[SpectrumMode.CUBES] = this.drawCubes.bind(this);
    // this.drawerCollection[SpectrumMode.ORBS] = this.drawOrbs.bind(this);
    this.drawerCollection[SpectrumMode.FIREWORKS] = this.drawFireworks.bind(this);
    // this.drawerCollection[SpectrumMode.FLOWERBLOCKS] = this.drawFlowerBlocks.bind(this);
    // this.drawerCollection[SpectrumMode.RING] = this.drawRing.bind(this);
    // this.drawerCollection[SpectrumMode.SHINE] = this.drawShine.bind(this);
    this.drawerCollection[SpectrumMode.SHOCKWAVE] = this.drawShockwave.bind(this);

    this.drawer = this.drawerCollection[SpectrumMode.SHOCKWAVE];
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }


  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    this.canvas = this.canvasRef.nativeElement;
    this.context = this.canvas.getContext('2d');
    this._helper = new SpectrumHelper(this.context);

    this._subs.push( this.metadata.subscribe( this.onMetadata.bind(this) ) ) ;
  }

  protected containerOnResized(entries): void {
    super.containerOnResized(entries);

    this.canvas.width = this.container.offsetWidth;
    this.canvas.height = this.container.offsetHeight;
    this.canvasRedraw();
  }


  collectionIndexOnChanged(index: number): void {
    super.collectionIndexOnChanged(index);

    this.spectrumItem = this.selectedItem as AudioSpectrumItem;
    this.drawer = this.drawerCollection[this.selectedItem.mode];
    this.canvasRedraw();
  }


  protected itemLanguageOnChanged(l: ILanguage): void {
    super.itemLanguageOnChanged(l);
    this.visualSpellConcept = '';
  }


  protected onMetadata(m: IAudioSyncMetadata): void {

    if (m.type !== this.visualSpellMode) {
      return;
    }
    this.visualSpellConcept = m.value ;
  }



  private drawSpectrum(spec: number[]): void {
    this._spectrum = spec;
    this.canvasRedraw();
  }

  public canvasRedraw(): void {
    if (!this.canvas || !this._spectrum || !this.spectrumItem){
      return;
    }

    this.context.clearRect(0, 0, this.canvas.width , this.canvas.height);


    this.drawer = this.drawerCollection[this.settingsService.settings.spectrum.mode];
    this.drawer();
  }





  private drawOrbs(): void {

    const helper = this._helper;
    let data = this._spectrum;
    const w = this.canvas.width;
    const h = this.canvas.height;
    const colors = this.settingsService.settings.spectrum.colors; // this.spectrumItem.colors;

    data = helper.mutateData(data, 'organize').mids;
    data = helper.mutateData(data, 'split', 2)[0];
    data = helper.mutateData(data, 'shrink', 100);
    data = helper.mutateData(data, 'mirror');
    data = helper.mutateData(data, 'scale', h);
    data = helper.mutateData(data, 'amp', .75);

    const points = helper.getPoints('line', w, [0, h / 2], data.length, data, { offset: 50 });
    points.start.forEach((start, i) => {
      helper.drawLine(start, points.end[i], { lineColor: colors[0] });

      helper.drawCircle(start, h * .01, { color: colors[1] || colors[0] });
      helper.drawCircle(points.end[i], h * .01, { color: colors[1] || colors[0] });
    });
  }


  private drawFlower(): void {

    const helper = this._helper;
    const ctx = this.context;
    const data = this._spectrum;
    const w = this.canvas.width;
    const h = this.canvas.height;
    const colors = this.settingsService.settings.spectrum.colors; // this.spectrumItem.colors;


    const min = 5;
    const r = h / 4;
    const offset = r / 3;
    const cx = w / 2;
    const cy = h / 2;
    const point_count = 128;
    const percent = (r - offset) / 255;
    const increase = (360 / point_count) * Math.PI / 180;
    const breakpoint = Math.floor(point_count / colors.length);

    for (let point = 1; point <= point_count; point++) {
      const p = (data[point] + min) * percent;
      const a = point * increase;

      const sx = cx + (r - (p - offset)) * Math.cos(a);
      const sy = cy + (r - (p - offset)) * Math.sin(a);
      ctx.moveTo(sx, sy);

      const dx = cx + (r + p) * Math.cos(a);
      const dy = cy + (r + p) * Math.sin(a);
      ctx.lineTo(dx, dy);

      if (point % breakpoint === 0) {
        const i = (point / breakpoint) - 1;
        ctx.strokeStyle = colors[i];
        ctx.stroke();
        ctx.beginPath();
      }
    }

    ctx.stroke();
  }

  private drawCubes(): void {

    const helper = this._helper;
    const ctx = this.context;
    let data = this._spectrum;
    const w = this.canvas.width;
    const h = this.canvas.height;
    const colors = this.settingsService.settings.spectrum.colors; // this.spectrumItem.colors;


    data = helper.mutateData(data, 'organize').base;
    data = helper.mutateData(data, 'shrink', 20).slice(0, 19);
    data = helper.mutateData(data, 'scale', h);

    const points = helper.getPoints('line', w, [0, h], data.length, data);

    const spacing = 5;
    const squareSize = (w / 20) - spacing;
    let colorIndex = 0;

    points.start.forEach((start, i) => {
      const squareCount = Math.ceil(data[i] / squareSize);

      // find color stops from total possible squares in bar
      const totalSquares = (h - (spacing * (h / squareSize))) / squareSize;
      const colorStop = Math.ceil(totalSquares / colors.length);

      for (let j = 1; j <= squareCount; j++) {
        const origin: [any , any] = [start[0], (start[1] - (squareSize * j) - (spacing * j))];
        helper.drawSquare(origin, squareSize, { color: colors[colorIndex], lineColor: 'black' });
        if (j % colorStop === 0) {
          colorIndex++;
          if (colorIndex >= colors.length) { colorIndex = colors.length - 1; }
        }
      }
      colorIndex = 0;
    });
  }

  private drawBars(): void {

    const helper = this._helper;
    const ctx = this.context;
    const data = this._spectrum;
    const w = this.canvas.width;
    const h = this.canvas.height;
    const colors = this.settingsService.settings.spectrum.colors; // this.spectrumItem.colors;


    const point_count = 64;
    const percent = h / 255;
    const increase = w / 64;
    const breakpoint = Math.floor(point_count / colors.length);

    for (let point = 1; point <= point_count; point++) {
      let p = data[point]; // get value
      p *= percent;

      const x = increase * point;

      ctx.moveTo(x, h);
      ctx.lineTo(x, h - p);

      if (point % breakpoint === 0) {
        const i = (point / breakpoint) - 1;
        ctx.strokeStyle = colors[i];
        ctx.stroke();
        ctx.beginPath();
      }
    }
  }





  private drawFireworks(): void {
    const helper = this._helper;
    const ctx = this.context;
    let data: any[] = this._spectrum;
    const w = this.canvas.width;
    const h = this.canvas.height;
    const colors = this.settingsService.settings.spectrum.colors; // this.spectrumItem.colors;

    data = helper.mutateData(data, 'shrink', 200).slice(0, 120);
    data = helper.mutateData(data, 'mirror');
    data = helper.mutateData(data, 'scale', (h / 4) + ((h / 4) * .35));

    const points = helper.getPoints('circle', h / 2, [w / 2, h / 2], data.length, data, { offset: 35, rotate: 270 });

    ctx.strokeStyle = colors[0];

    points.start.forEach((start, i) => {
      helper.drawLine(start, points.end[i]);
    });

    helper.drawPolygon(points.start, { close: true });

    points.end.forEach((end, i) => {
      helper.drawCircle(end, h * .01, { color: colors[1] });
    });
  }



  private drawShockwave(): void {

    const helper = this._helper;
    const ctx = this.context;
    let data: any[] = this._spectrum;
    const w = this.canvas.width;
    const h = this.canvas.height;
    const colors = this.settingsService.settings.spectrum.colors; // this.spectrumItem.colors;


    data = helper.mutateData(data, 'shrink', 300);
    data = helper.mutateData(data, 'scale', h / 2);
    data = helper.mutateData(data, 'split', 4).slice(0, 3);

    let colorIndex = 0;
    data.forEach((points) => {
      const wavePoints = helper.getPoints('line', w, [0, h / 2], points.length, points);
      helper.drawPolygon(wavePoints.end, { lineColor: colors[colorIndex], radius: (h * .015) });

      const invertedPoints = helper.getPoints('line', w, [0, h / 2], points.length, points, { offset: 100 });
      helper.drawPolygon(invertedPoints.start, { lineColor: colors[colorIndex], radius: (h * .015) });
      colorIndex++;
    });
  }




}
