import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  /* CONFIG ********************************************************** */

  public static __config: any;

  public static setConfig(d: any): void {
    ConfigService.__config = d;
  }

  public static getConfig(): any {
    return ConfigService.__config ;
  }

  public get config(): any {
    return ConfigService.getConfig() ;
  }





  public static getValue(path: string): any {

    try{
      // tslint:disable-next-line:no-eval
      return eval('ConfigService.__config.' + path);
    }catch (e) {
      return null;
    }
  }






  constructor() {
    //
  }




  public getValue(path: string): any {
    return ConfigService.getValue(path);
  }



}
