import { Injectable } from '@angular/core';
import {Favorite, ItemModel} from 'projects/library/src/model/item-model';
import {ConfirmDialogData} from 'projects/library/src/model/confirm-dialog-data';
import {MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogComponent} from 'projects/visitor/src/app/components/dialogs/confirm-dialog/confirm-dialog.component';
import {LocalStorageService} from 'projects/visitor/src/app/services/local-storage/local-storage.service';
import {ItemService} from 'projects/library/src/services/item/item.service';
import {LanguageService} from 'projects/library/src/services/language/language.service';

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {

  private _favorites: Favorite[];

  constructor(
    public localStorageService: LocalStorageService,
    public languageService: LanguageService,
    public itemService: ItemService,
  ) {
    this.init();
  }




  init(): void {

    // Localizamos los favoritos
    this._favorites = this.localStorageService.getItem('favorites');

    // NO hay favoritos? los creamos
    if (!this._favorites){
      this.localStorageService.setItem('favorites' , [] );
    }

    this._favorites = this.localStorageService.getItem('favorites');
  }

  private save(): boolean {
    try{
      this.localStorageService.setItem('favorites' , this._favorites);
      return true;
    }catch (e) {
      console.warn('saving favorites' , e);
      return false;
    }
  }


  exists(id: string): boolean {
    return !!this._favorites.find(f => f.id === id);
  }


  add(f: Favorite): boolean {
    this._favorites.unshift(f);
    return this.save();
  }

  remove(f: Favorite): boolean {
    const pos: number = this.indexOf(f);
    if (pos >= 0){
      this._favorites.splice(pos , 1);
      return this.save();
    }
    return false;
  }



  find(f: Favorite): Favorite {
    return this.findById(f.id);
  }

  findById(id: string): Favorite {
    return this._favorites.find(f => f.id === id );
  }

  indexOf(f: Favorite): number {
    for (let i: number = 0 ; i < this._favorites.length ; i++ ){
      if (this._favorites[i].id === f.id) {
        return i;
      }
    }
    return -1;
  }


  get favorites(): Favorite[] {
    return [...this._favorites];
  }




}
