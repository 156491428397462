import { Injectable } from '@angular/core';
import {LoggerInterface} from 'projects/library/src/services/logger/logger.interface';
import {LoggerService} from 'projects/library/src/services/logger/logger.service';
import isMobile from 'is-mobile';
import screenfull from 'screenfull';


@Injectable({
  providedIn: 'root'
})
export class FullscreenService {

  private _logger: LoggerInterface;
  public isMobile: boolean;
  public isFullscreenEnabled: boolean;

  constructor() {
    this.isMobile = isMobile();
    this.isFullscreenEnabled = screenfull.isEnabled ;
    this._logger = LoggerService.getLogger('Fullscreen Service');

  }


  public isFullscreen(): boolean {
    return screenfull.isFullscreen;
  }

  public get fullscreen(): boolean {
    return screenfull.isFullscreen;
  }


  public activate(): void {
    if (!this.isMobile || !this.isFullscreenEnabled){
      this._logger.ENABLED && this._logger.log('cant go fullscreen' , this.isMobile , this.isFullscreenEnabled );
      return;
    }
    screenfull.request(undefined , {navigationUI : 'hide' } );
  }


  public deactivate(): void {
    screenfull.exit();
  }


  public toggle(): void {
    screenfull.toggle();
  }

}
