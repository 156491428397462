<div class="media-collection" *ngIf="collection.items.length>1" style="z-index: 1">

  <button mat-button class="arrow-nav-left" [class.disabled]="!hasPrev()" (click)="prev()">
<!--
    <mat-icon svgIcon="play_arrow" aria-hidden="false" aria-label="left"></mat-icon>
-->
    <mat-icon aria-hidden="false" aria-label="left">play_circle</mat-icon>

  </button>


  <button mat-button class="arrow-nav-right" [class.disabled]="!hasNext()"  (click)="next()">
<!--
    <mat-icon svgIcon="play_arrow" aria-hidden="false" aria-label="resume"></mat-icon>
-->
    <mat-icon aria-hidden="false" aria-label="left">play_circle</mat-icon>
  </button>



  <div class="dots">
    <div *ngFor="let dot of this.collection.items; let i = index; " class="dot" [class.selected]="index === i" (click)="gotoIndex(i)" ></div>
  </div>


</div>
