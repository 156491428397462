import {EventEmitter, Injectable} from '@angular/core';
import {LoggerInterface} from 'projects/library/src/services/logger/logger.interface';
import {HttpLoaderService} from 'projects/library/src/services/http-loader/http-loader.service';
import {ItemModel} from 'projects/library/src/model/item-model';
import {DynamoResponseMultiple, DynamoResponseSingle} from 'projects/library/src/model/aws-interfaces';
import {LoggerService} from 'projects/library/src/services/logger/logger.service';
import {ConfigService} from 'projects/library/src/services/config/config.service';
import {ItemSync} from 'projects/library/src/model/item-sync';
import {ILanguage} from 'projects/library/src/services/language/i-language';
import {Observable, Subject} from 'rxjs';
import {LanguageService} from 'projects/library/src/services/language/language.service';
import {HttpLoadMode} from 'projects/library/src/services/http-loader/http-load-mode';
import {map} from 'rxjs/operators';
import {LatencyService} from 'projects/library/src/services/latency/latency.service';

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  public static __config: any;

  private _logger: LoggerInterface;

  public item: ItemModel;
  public itemList: ItemModel[];
  public sync: ItemSync;


  public item$: EventEmitter<ItemModel>;
  public error$: EventEmitter<string>;
  public sync$: EventEmitter<ItemSync>;


  public language: ILanguage;
  public language$: Subject<ILanguage>;


  public static setConfig(d: any): void {
    ItemService.__config = d;
  }

  public static getConfig(): any {
    return ItemService.__config ;
  }




  constructor(
    private _httpLoaderService: HttpLoaderService,
    private _languageService: LanguageService,
    // private _latencyService: LatencyService
  ) {
    this._logger = LoggerService.getLogger('Item Service');
    this.error$ = new EventEmitter<string>();
    this.item$ = new EventEmitter<ItemModel>();
    this.sync$ = new EventEmitter<ItemSync>();

    this.language$ = new Subject<ILanguage>();
  }


  private get _config(): any {
    return ItemService.getConfig() ;
  }


/*
  public itemGetById(id: string): void {
    const oConfig: any = ConfigService.getValue('item');
    const url: string = `./assets/mock/${id}/item.json`;
    const method: string = oConfig.get_by_id_method;
    const oBody: any = {itemID: id};
    this._httpLoaderService.httpLoad( url , method , oBody ).subscribe(this.itemOnMongoResult.bind(this) );
  }
*/

  public itemGetById(id: string): void {
    this._httpLoaderService.httpLoad(
      'https://lbi8kmcaj3.execute-api.us-west-2.amazonaws.com/default/prosodik_item' ,
      HttpLoadMode.GET ,
      null ,
      {
        itemID: id,
        action: 'findById'
      }).subscribe(this.itemOnDynamoResult.bind(this) );
  }


  private itemOnDynamoResult(dbResult: DynamoResponseSingle<ItemModel> ): void {
    if (
      !dbResult ||
      !dbResult.Item
    ) {
      const msg: string = 'No item found with given id';
      this._logger.ENABLED && this._logger.log(msg);
      this.error$.emit(msg);
      return;
    }

    this.itemOnSucess(dbResult.Item);
  }

  private itemOnMongoResult(dbResult: ItemModel ): void {
    if (
      !dbResult
    ) {
      const msg: string = 'No item found with given id';
      this._logger.ENABLED && this._logger.log(msg);
      this.error$.emit(msg);
    }

    this.itemOnSucess(dbResult);
  }


  private itemOnSucess(oItem: ItemModel ): void {

    // Remember spectacle.
    this.item = oItem;

    this.languageFind();


    this.item$.emit(this.item);
  }





  public syncGetById(id: string): void {
    const oConfig: any = ConfigService.getValue('item');
    const url: string = oConfig.get_sync_url;
    const method: string = oConfig.get_sync_method;
    const oBody: any = {itemID: id , type: 'get'};

    const oUrl: URL = new URL(url);
    Object.keys(oBody).forEach(key => oUrl.searchParams.append(key, oBody[key]));

    this._httpLoaderService.httpLoad( oUrl.toString() , method  ).subscribe(this.syncOnDynamoResult.bind(this) );
  }


  private syncOnDynamoResult(dbResult: DynamoResponseSingle<ItemSync> ): void {
    if (
      !dbResult ||
      !dbResult.Item
    ) {
      const msg: string = 'No sync found with given id';
      this._logger.ENABLED && this._logger.error(msg);
      // this.error$.emit(msg);
      return;
    }
    // Remember spectacle.
    this.sync = dbResult.Item;
    this.sync$.emit(this.sync);
  }


  /*
  Tomamos X lecturas y nos quedamos con la mejor....
   */






  private languageFind(): void {
    let oLanguage: ILanguage;

    // Find same language as visitor ?
    oLanguage = this.item.languages.find(value => value.code === this._languageService.languageSelected.code );

    // No?
    if (!oLanguage){
      oLanguage = this.item.languages[0];
    }
    this.languageSelect(oLanguage);
  }


  languageSelect(lang: ILanguage): void {
    this.language = lang;
    this.language$.next(this.language);
  }




  itemsByLocationID(id: string): Observable<ItemModel[]> {
    return this._httpLoaderService.httpLoad(
      'https://lbi8kmcaj3.execute-api.us-west-2.amazonaws.com/default/prosodik_item' ,
      HttpLoadMode.GET ,
      null ,
      {
        locationID: id,
        action: 'filterByLocation'
      }).pipe(
      map((dbResponse: DynamoResponseMultiple<ItemModel> ) => {
        return dbResponse.Items;
      } )
    );
  }





}
