import { Injectable } from '@angular/core';
import {LocalStorageService} from 'projects/visitor/src/app/services/local-storage/local-storage.service';
import {getEnumValues} from 'projects/library/src/util/enum/enum.util.enum';


export enum SpectrumMode {
  FIREWORKS= 'fireworks',
  BARS = 'bars',
  CUBES = 'cubes',
  FLOWER = 'flower',
  SHOCKWAVE = 'shockwave'
}

interface SpectrumSettings {
  mode: SpectrumMode;
  colors: string[];
}

export enum SpellMode{
  SENTENCE = 'sentence',
  WORD = 'word'
}

interface SpellSettings {
  sentenceActive: boolean;
  sentenceScroll: boolean;
  sentenceColor: string;
  wordActive: boolean;
  wordColor: string;
}

interface Settings {
  spectrum: SpectrumSettings;
  spell: SpellSettings;
}


@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private defaultSettings: Settings = {
    spectrum: {
      mode: SpectrumMode.FIREWORKS ,
      colors: ['#ff0072' , '#a800ff' , '#004eff' , '#00ffa2' , '#6cff00' , '#ffde00' , '#ff2a00' , '#6cff00' , '#ffde00' , '#ff2a00' ]
    },
    spell: {
      sentenceActive: true ,
      sentenceScroll: true,
      sentenceColor: '#00FFFF',
      wordColor: '#00FFFF',
      wordActive: true ,
    }
  };

  public SpectrumMode: SpectrumMode;
  public spectrumModes: SpectrumMode[];

  public settings: Settings;

  constructor(
    private _localStorageService: LocalStorageService
  ) {

    this.settings = this._localStorageService.getItem('settings');
    if (!this.settings) {
      this.settings = JSON.parse(JSON.stringify(this.defaultSettings));
      this.save();
    }

    this.spectrumModes = getEnumValues(SpectrumMode);
  }


  public save(): void {
    this._localStorageService.setItem('settings' , this.settings);
  }


}
