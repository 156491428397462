import {IAudioPlayer, IAudioSyncMetadata} from 'projects/library/src/audioPlayer/implementation/i-audio-player';
import {AudioItemFile, AudioItemSynthesis} from 'projects/library/src/model/item-model';
import {EventEmitter} from '@angular/core';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {ILanguage} from 'projects/library/src/services/language/i-language';
import {AudioPlayerFactory} from 'projects/library/src/audioPlayer/audio-player-factory';

export class AudioPlayer implements IAudioPlayer {

  private _implementation: IAudioPlayer;

  public progress: number = 0;
  public position: number = 0;

  public progress$: BehaviorSubject<number>;
  public position$: EventEmitter<number>;
  public complete$: EventEmitter<boolean>;
  public loaded$: EventEmitter<boolean>;
  public error$: EventEmitter<string>;
  public metadata$: Subject<IAudioSyncMetadata>;
  public spectrum$: EventEmitter<number[]>;
  public spectrum: number[];

  private _onPosition: any;
  private _onComplete: any;
  private _onLoaded: any;
  private _onMetadata: any;
  private _onSpectrum: any;
  private _onError: any;

  private _subs: Subscription[];
  protected _timeouts: number[];


  constructor(
    //
  ) {
    this.progress$ = new BehaviorSubject<number>(0);
    this.position$ = new EventEmitter<number>();
    this.complete$ = new EventEmitter<boolean>();
    this.loaded$ = new EventEmitter<boolean>();
    this.error$ = new EventEmitter<string>();
    this.metadata$ = new Subject<IAudioSyncMetadata>();
    this.spectrum$ = new EventEmitter<number[]>();

    this._onPosition = this.onPosition.bind(this);
    this._onComplete = this.onComplete.bind(this);
    this._onLoaded = this.onLoaded.bind(this);
    this._onMetadata = this.onMetadata.bind(this);
    this._onError = this.onError.bind(this);
    this._onSpectrum = this.onSpectrum.bind(this);

    this._subs = [];
    this._timeouts = [];

  }

  setData(text: string, lang: ILanguage , audioDescriptor: AudioItemSynthesis | AudioItemFile): void {

    try{
      this.clear();
      this._implementation = AudioPlayerFactory.instanceGet(audioDescriptor.type , text , lang , audioDescriptor) ;
      this._implementation.position$.subscribe(this._onPosition );
      this._implementation.complete$.subscribe(this._onComplete );
      this._implementation.loaded$.subscribe(this._onLoaded );
      this._implementation.error$.subscribe(this._onError );
      this._implementation.metadata$.subscribe(this._onMetadata );
      this._implementation.spectrum$.subscribe(this._onSpectrum );

      this.reset();
      this.onSpectrum(new Array(512).fill(0) );

    }catch (e: any){
      this.onError(e);
    }
  }



  private onPosition(pos: number): void {
    this.position = pos;
    this.position$.emit(this.position);

    this.progress = pos / this.getDuration() ;
    this.progress$.next(this.progress);
  }

  private onComplete(b: boolean): void {
    this.complete$.emit(b);
  }

  private onLoaded(b: boolean): void {
    this.loaded$.emit(b);
  }

  private onMetadata(m: IAudioSyncMetadata): void {
    this.metadata$.next(m);
  }

  private onError(s: string): void {
    this.error$.emit(s);
  }
  private onSpectrum(s: number[]): void {
    this.spectrum = s;
    this.spectrum$.emit(s);
  }


  public setLoop(b: boolean): void {
    return this._implementation?.setLoop(b);
  }

  public play(ms?: number): Promise<void> {
    return this._implementation?.play(ms);
  }

  public pause(): void{
    this._implementation?.pause();
  }

  public resume(): void{
    this._implementation?.resume();
  }

  public seek(ms: number): void{
    this._implementation?.seek(ms);
  }

  public getDuration(): number{
    return this._implementation?.getDuration();
  }

  public clear(): void{
    this._subs.forEach(s => s.unsubscribe());
    this._timeouts.forEach(n => window.clearTimeout(n) );

    this.reset();
    this._implementation?.clear();
  }


  public reset(): void {
    // this.pause();
    this.progress = 0;
    this.position = 0;
  }

  public get playing(): boolean {
    return this._implementation ? this._implementation.playing : false ;
  }



  muteToggle(): void{
    this._implementation.muteToggle();
  }
  public get muted(): boolean {
    return this._implementation ? this._implementation.muted : false ;
  }

}
