import { Pipe, PipeTransform } from '@angular/core';

declare let QRCode: any;


@Pipe({
  name: 'qr'
})
export class QrPipe implements PipeTransform {


  transform(url: string, w: number , h: number): string {

    const oDiv: HTMLDivElement = document.createElement('div');
    const qr: any = new QRCode(oDiv , {
      text: url,
      width: w,
      height: h,
      colorDark : '#000000',
      colorLight : '#ffffff',
      correctLevel : QRCode.CorrectLevel.H,
    });
    const oCanvas: HTMLCanvasElement = oDiv.childNodes[0] as HTMLCanvasElement;
    return oCanvas.toDataURL();
  }

}
