<div #container class="media-collection" >

  <div *ngIf="animationList.length" style="position: absolute; left: 5px; top: 5px; z-index: 5;" >
    <button mat-button [matMenuTriggerFor]="menu">
      <mat-icon>video_library</mat-icon>
    </button>
    <mat-menu #menu="matMenu" >
      <button mat-menu-item *ngFor="let anim of animationList" (click)="animationPlay(anim)">{{anim}}</button>
    </mat-menu>
  </div>


    <!--
  <model-viewer #viewerRef alt="Neil Armstrong's Spacesuit from the Smithsonian Digitization Programs Office and National Air and Space Museum" src="https://modelviewer.dev/shared-assets/models/NeilArmstrong.glb" ar ar-modes="webxr scene-viewer quick-look" environment-image="https://modelviewer.dev/shared-assets/environments/moon_1k.hdr" poster="https://modelviewer.dev/shared-assets/models/NeilArmstrong.webp" seamless-poster shadow-intensity="1" camera-controls></model-viewer>
      Editor de hotspots
      https://modelviewer.dev/editor/
    -->

    <!-- -->
  <model-viewer #viewerRef style="width: 100%; height: 100%" src="{{selectedModel?.url}}"  camera-controls (load)="modelOnLoad()" ar ar-modes="webxr scene-viewer quick-look fallback" >

    <div #div *ngFor="let h of hotSpots"
      class="model-hotspot"
      [slot]="h.slot"
      [attr.data-position]="h.position"
      [attr.data-normal]="h.normal"
      [attr.data-visibility-attribute]="h.visibility"
      (click)="hotspotClick(div , h)"
    >
      <span class="model-info"><span>i</span></span>
      <span [class]="h.opened ? 'visible' : 'invisible' " class="model-label">{{ languageService.translate(h , 'title' , itemLanguage.code ) }}</span>
    </div>

  </model-viewer>

</div>

<!--overlay con la controladora de la galeria-->
<app-collection-controller [collection]="collection" (indexSelected)="collectionIndexOnChanged($event)" ></app-collection-controller>
