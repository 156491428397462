/* tslint:disable:no-string-literal */
import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LanguageService} from 'projects/library/src/services/language/language.service';
import {InfoAbstractItemComponent} from 'projects/visitor/src/app/components/info/info-abstract-item/info-abstract-item.component';
import {InfoText} from 'projects/library/src/model/item-model';
import {Subject} from 'rxjs';
import {IAudioSyncMetadata} from 'projects/library/src/audioPlayer/implementation/i-audio-player';
import {SettingsService, SpellMode} from 'projects/visitor/src/app/services/settings/settings.service';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';


@Component({
  selector: 'app-info-text-item',
  templateUrl: './info-text-item.component.html',
  styleUrls: ['./info-text-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class InfoTextItemComponent extends InfoAbstractItemComponent implements OnInit, OnDestroy, AfterViewInit {


  @Input()
  metadata: Subject<IAudioSyncMetadata>;

  @ViewChild('containerRef')
  containerRef: ElementRef;
  container: HTMLDivElement;

  @ViewChild('highlightRef')
  highlightRef: ElementRef;
  highlightSpan: HTMLSpanElement;


  public textItem: InfoText;
  public textTranslated: string;

  public posMin: number = 0 ;
  public posStart: number = 0;
  public posEnd: number = 0 ;
  public posMax: number = 0 ;



  constructor(
    public languageService: LanguageService,
    public settingsService: SettingsService,
  ) {
    super(languageService);
  }


  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    this.container = this.containerRef.nativeElement;
    this.highlightSpan = this.highlightRef.nativeElement;
    this._subs.push( this.metadata.subscribe( this.onMetadata.bind(this) ) ) ;
  }


  protected itemOnReady(): void {
    super.itemOnReady();
    this.textItem = this.item as InfoText;
    this.itemLanguageOnChanged();
  }

  protected itemLanguageOnChanged(): void {
    super.itemLanguageOnChanged();
    try{
      this.textTranslated = this.languageService.translate(this.textItem , 'text' , this.lang.code );
      this.positionReset();
    }catch (e) {
      //
    }
  }


  //
  private positionReset(): void {
    this.posMin = 0 ;
    this.posStart = 0 ;
    this.posEnd = 0 ;
    this.posMax = this.textTranslated.length;
  }


  protected onMetadata(m: IAudioSyncMetadata): void {

    // Llega una palabra? paso
    if (m.type !== SpellMode.SENTENCE ){
      return;
    }

    // No Activo?  devolver texto integro....
    if (!this.settingsService.settings.spell.sentenceActive){
      this.positionReset();
    }

    // show sentence
    this.posStart = m.start;
    this.posEnd = m.end;

    // Highlight
    if (this.settingsService.settings.spell.sentenceScroll){
      // this.highlightSpan.scrollIntoView(true ); // true , {behavior: 'smooth' , block: 'center' }
      elementScrollIntoView(this.highlightSpan , {behavior: 'smooth' , block: 'center' } ) ;
    }
  }


}
