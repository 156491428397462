import {AfterViewInit, ChangeDetectionStrategy, Component, HostListener, NgZone, OnDestroy, OnInit} from '@angular/core';
import {MediaCollectionComponent} from 'projects/visitor/src/app/components/media/media-collection/media-collection.component';
import {MediaGigapixel, MediaGigapixelHotspot, MediaModel} from 'projects/library/src/model/item-model';
import {LanguageService} from 'projects/library/src/services/language/language.service';


declare var OpenSeadragon;



@Component({
  selector: 'app-media-gigapixel-collection',
  templateUrl: './media-gigapixel-collection.component.html',
  styleUrls: ['./media-gigapixel-collection.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MediaGigapixelCollectionComponent extends MediaCollectionComponent implements OnInit, OnDestroy, AfterViewInit {

  public selectedGigapixel: MediaGigapixel;
  public hotSpots: MediaGigapixelHotspot[];

  private viewer: any;

  constructor(
    private ngZone: NgZone,
    public languageService: LanguageService
  ) {
    super();
    // this.loadScript('./assets/scripts/openseadragon-bin-3.0.0/openseadragon.min.js' , false);
  }


  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.viewer?.destroy();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  collectionIndexOnChanged(index: number): void {
    super.collectionIndexOnChanged(index);
    this.selectedGigapixel = this.selectedItem as MediaGigapixel;
    this.viewerCreate();
  }


  async viewerCreate(): Promise<void> {
    this.viewer?.destroy();

    this.hotspotsCreate();

    // Cargamos la url 1º (para que tarde un poco y se cachee)
    await fetch(this.selectedGigapixel.url);

    const oConfig: any = {
      element: this.container ,
      tileSources: this.selectedGigapixel.url,
      prefixUrl: './assets/icons/openseadragon/',
      overlays: this.hotSpots
    };

    // As per recomendastion
    this.viewer = this.ngZone.runOutsideAngular(() =>
      new OpenSeadragon.Viewer( oConfig )
        .addOnceHandler('open', (e) => {
          this.hotspotsUpdate();
        })
    );

  }


  private hotspotsCreate(): void {

    this.hotSpots = JSON.parse(JSON.stringify(this.selectedGigapixel.hotSpots));
    for (let i: number = 0 ; i < this.hotSpots.length ; i++ ){
      const h: MediaGigapixelHotspot = this.hotSpots[i];
      h.id = 'hotspot_' + i;
      h.width = 0;
      h.height = 0;
      h.className = 'gigapixel-hotspot' ;
      h.checkResize = false;
      // h.placement = 'CENTER';
    }


  }


  private hotspotsUpdate(): void {
    this.container.querySelectorAll('a.gigapixel-hotspot' )
      .forEach( (oLink: HTMLAnchorElement , key: number, parent: NodeListOf<HTMLAnchorElement> ) => {
        const h: MediaGigapixelHotspot = this.hotSpots.find( hp => hp.id === oLink.id);

        let tmpDiv: HTMLDivElement;
        let oSpan: HTMLSpanElement;

        tmpDiv = document.createElement('div');
        tmpDiv.innerHTML = '<span class="gigapixel-info"><span>i</span></span>';
        oSpan = tmpDiv.firstElementChild as HTMLSpanElement;
        oLink.appendChild(oSpan);


        tmpDiv.innerHTML = '<span class="gigapixel-label">' + this.languageService.translate(h , 'title' , this.itemLanguage.code ) + '</span>';
        oSpan = tmpDiv.firstElementChild as HTMLSpanElement;
        oLink.appendChild(oSpan);

        // Closed by default
        this.hotspotLabelOpen(oSpan , false);

        // MouseTracker is required for links to function in overlays
        new OpenSeadragon.MouseTracker({
          element: oLink,
          // clickHandler: (event) => {
          //   this.hotspotLabelOpen(oSpan , true);
          // },
          pressHandler: (event) => {
            console.log('press' , event.pointerType);
            this.hotspotLabelOpen(oSpan , true);
          },
        });
    } );
  }


  private hotspotLabelOpen(oSpan: HTMLSpanElement , b: boolean): void {
    if (b){
      oSpan.classList.remove('invisible');
      oSpan.classList.add('visible');
    }
    else {
      oSpan.classList.remove('visible');
      oSpan.classList.add('invisible');
    }
  }




  private targetIsHotspot(oTarget , className ): boolean {
    let oParent: HTMLElement;

    oParent = oTarget;
    while (oParent && oParent.parentElement ){
      if (oParent.classList.contains(className) ){
        return true;
      }
      oParent = oParent.parentElement;
    }
  }


  public hotspotReleaseAll(oTarget?: HTMLElement): void {
    console.log('hotspotReleaseAll' , oTarget);

    if ( this.targetIsHotspot(oTarget , 'gigapixel-hotspot') ) {
      return;
    }

    this.container.querySelectorAll<HTMLSpanElement>('span.gigapixel-label' ).forEach( oSpan => {
      this.hotspotLabelOpen(oSpan , false);
    } );
  }


  @HostListener('window:pointerup', ['$event'])
  handlePointer(event: PointerEvent): void  {
    console.log('window:pointerup');
    this.hotspotReleaseAll(event.target as HTMLElement);
  }


}
