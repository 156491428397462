<div #container class="media-collection" >
  <img #cylinderImageRef src="{{this.selectedCylinder?.url}}" (load)="imageOnload(cylinderImageRef)" style="display: none" >

  <div #cylinderDivRef
       style="position: relative"
       [hidden]="!cylinderImage"
       [ngStyle]="{
          'width.px' : this.frameWidth ,
          'height.px' : this.frameHeight ,
          'aspect-ratio' : this.frameRatio ,
          'background-image' : 'url(' +  this.selectedCylinder?.url + ')' ,
          'background-position': this.frameX + 'px ' + this.frameY + 'px' ,
          'transform': 'scale(' + this.divScale + ')'
       }"
       (mousedown)="mouseEvent($event)"
       (mousemove)="mouseEvent($event)"
       (mouseup)="mouseEvent($event)"
       (touchstart)="touchEvent($event)"
       (touchmove)="touchEvent($event)"
       (touchend)="touchEvent($event)"
       (touchcancel)="touchEvent($event)"
  >
  </div>

  <div
    class="cylinder-hotspot-container"
    [ngStyle]="{
        'width.px' : this.frameWidth * divScale,
        'height.px' : this.frameHeight * divScale,
        'aspect-ratio' : this.frameRatio
    }">
    <div #div class="cylinder-hotspot" *ngFor="let h of this.selectedCylinder?.hotSpots"  [ngStyle]="hotspotGetStyle(h)" >
      <span class="cylinder-info" (click)="hotspotClick(div , h)"><span>i</span></span>
      <span [class]="h.opened ? 'visible' : 'invisible' " class="cylinder-label">{{languageService.translate(h , 'title' , itemLanguage.code )}}</span>
    </div>

  </div>



  <!--compass-->
  <div style="position: absolute; left:10px; top: 10px; user-select: none; display: flex; flex-direction: row; align-items: center;filter: drop-shadow(black 0px 0px 2px);">
    <img src="./assets/icons/compass.svg" style="width:24px; height:24px; transform: rotateZ({{this.frameRotation}}deg; ">
    <span style="margin-left: 5px; font-size: small; color: #999;">{{this.frameRotation | number : '1.0-0' }}º</span>
  </div>


</div>

<!--overlay con la controladora de la galeria-->
<app-collection-controller [collection]="collection" (indexSelected)="collectionIndexOnChanged($event)" ></app-collection-controller>
