import {ITranslatable} from 'projects/library/src/services/language/i-translatable';
import {ILanguage} from 'projects/library/src/services/language/i-language';






export enum AudioItemType {
  SYNTHESIS = 'synthesis',
  FILE = 'file'
}



export interface AudioItem {
  type: AudioItemType;
}
export interface AudioItemSynthesis extends AudioItem{
  rate: number;
  pitch: number;
}

export interface AudioItemFile extends AudioItem {
  url: string;
  metadata?: string;
}

export type AudioItemDescriptor = AudioItemFile ; // AudioItemSynthesis |
export interface AudioItemCollection {
  [lang: string]: AudioItemDescriptor ;
}









export enum AudioSpectrumType {
  BARS = 'bars',
  CUBES = 'cubes',
  FLOWER = 'flower',
  // ORBS = 'orbs',
  FIREWORKS = 'fireworks',
  // FLOWERBLOCKS = 'flowerblocks',
  // RING = 'ring',
  // SHINE = 'shine',
  SHOCKWAVE = 'shockwave',
  // WAVE = 'wave',
}



export interface MediaItem extends ITranslatable{
  // url: string;
  [key: string]: any;
}

export interface MediaSource extends MediaItem{
  url: string;
}


export interface MediaItemCollection<T> {
  type: string;
  items: T[];
}




export interface AudioSpectrumItem extends MediaItem {
  //
}


export interface MediaModelHotspot extends ITranslatable{
  slot: string;
  position: string;
  normal: string;
  visibility: string;
  opened: boolean;
}
export interface MediaModel extends MediaSource{
  hotSpots: MediaModelHotspot[];
}

export interface MediaImage extends MediaSource{
  //
}

export interface MediaVideo extends MediaSource{
  //
}

export interface MediaImageDual extends MediaItem{
  left: string;
  right: string;
}

export enum MediaCompareMode {
  SPLIT = 'split',
  FADE = 'fade'
}

export interface MediaCompare extends MediaImageDual{
  mode: MediaCompareMode;
}

export interface MediaFade extends MediaImageDual{
  //
}

export interface MediaSphericalHotspot extends ITranslatable{
  pitch: number;
  yaw: number;
  type?: string;
  text?: string;
  cssClass?: string;
  createTooltipFunc?: string | any ;
  createTooltipArgs?: any ;
}


export interface MediaSpherical extends MediaSource{
  hotSpots: MediaSphericalHotspot[];
}

export interface MediaGigapixelHotspot extends MediaItem{
  id: string;
  x: number;
  y: number;
  placement: string;
  checkResize: boolean;
  className: string;
  opened: boolean;
}

export interface MediaGigapixel extends MediaSource{
  hotSpots: MediaGigapixelHotspot[];
}


export interface MediaCylinderHotspot extends ITranslatable{
  x: number;
  y: number;
  angle: number;
  opened: boolean;
}

export interface MediaCylinder extends MediaSource{
  frameAmount: number;
  direction: 'horizontal' |'vertical' ;
  hotSpots: MediaCylinderHotspot[];
}



export interface MediaLocation extends MediaSource{
  position: {
    x: number;
    y: number;
  };
}




export interface MediaAr extends MediaSource{
  features: string;
  model: string;
}





export interface InfoItem  extends ITranslatable {
  type: string;
}

export interface InfoText extends InfoItem , ITranslatable {

}


export interface RelationItem extends InfoItem {
  id: string;
}

export interface RelationTagItem extends RelationItem {
  //
}

export interface RelationLinkItem extends RelationItem {
  icon: string;
}

/*
export interface RelationCollection extends InfoItem  {
  items: RelationItem[];
}
*/

export interface RelationTagCollection extends InfoItem  {
  items: RelationTagItem[];
}

export interface RelationLinkCollection extends InfoItem  {
  items: RelationLinkItem[];
}


export interface Favorite extends ITranslatable  {
  id: string;
  icon: string;
}

export interface FavoriteCollection extends InfoItem  {
  items: Favorite[];
}





export interface ItemModel {
  itemID: string;
  locationID: string;
  tag: string;
  media: MediaItemCollection<MediaItem>[];
  information: InfoItem[];
  languages: ILanguage[];
  audio: AudioItemCollection;
  icon: string;
  sync: boolean;
}

