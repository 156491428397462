import {APP_INITIALIZER, NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import {AppComponent} from 'projects/visitor/src/app/components/app/app.component';
import {ErrorComponent} from 'projects/visitor/src/app/components/error/error.component';
import {HomeComponent} from 'projects/visitor/src/app/components/home/home.component';
import {ItemComponent} from 'projects/visitor/src/app/components/item/item.component';
import {ConfigService} from 'projects/library/src/services/config/config.service';
import {ErrorService} from 'projects/library/src/services/error/error.service';
import {HttpLoaderService} from 'projects/library/src/services/http-loader/http-loader.service';
import {JsonService} from 'projects/library/src/services/json/json.service';
import {LanguageService} from 'projects/library/src/services/language/language.service';
import {LoggerService} from 'projects/library/src/services/logger/logger.service';
import {NavigationService} from 'projects/library/src/services/navigation/navigation.service';
import {NosleepService} from 'projects/visitor/src/app/services/nosleep/nosleep.service';
import {preloadFactory} from 'projects/library/src/bootstrap/preload-factory';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {RoutingModule} from 'projects/visitor/src/routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCommonModule} from '@angular/material/core';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTreeModule} from '@angular/material/tree';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatChipsModule} from '@angular/material/chips';
import {AngularSplitModule} from 'angular-split';
import { MediaSpectrumVisualizerComponent } from './components/media/media-spectrum-visualizer/media-spectrum-visualizer.component';
import { MediaModelCollectionComponent } from './components/media/media-model-collection/media-model-collection.component';
import { CollectionControllerComponent } from './components/media/collection-controller/collection-controller.component';
import { MediaCollectionComponent } from 'projects/visitor/src/app/components/media/media-collection/media-collection.component';
import { MediaCylinderCollectionComponent } from './components/media/media-cylinder-collection/media-cylinder-collection.component';
import { MediaSphericalCollectionComponent } from './components/media/media-spherical-collection/media-spherical-collection.component';
import { MediaGigapixelCollectionComponent } from './components/media/media-gigapixel-collection/media-gigapixel-collection.component';
import { MediaCompareCollectionComponent } from './components/media/media-compare-collection/media-compare-collection.component';
import { MediaImageCollectionComponent } from './components/media/media-image-collection/media-image-collection.component';
import { MediaVideoCollectionComponent } from './components/media/media-video-collection/media-video-collection.component';
import { MediaArCollectionComponent } from './components/media/media-ar-collection/media-ar-collection.component';
import {InfoTextItemComponent} from 'projects/visitor/src/app/components/info/info-text-item/info-text-item.component';
import { InfoDataItemComponent } from './components/info/info-data-item/info-data-item.component';
import { InfoAbstractItemComponent } from './components/info/info-abstract-item/info-abstract-item.component';
import {MatMenuModule} from '@angular/material/menu';
import { RelationTagComponent } from './components/info/relation-tag/relation-tag.component';
import { RelationLinkComponent } from './components/info/relation-link/relation-link.component';
import { MediaLocationCollectionComponent } from './components/media/media-location-collection/media-location-collection.component';
import { ShareComponent } from './components/share/share.component';
import {MatListModule, MatNavList} from '@angular/material/list';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
import { ScannerDialogComponent } from './components/dialogs/scanner-dialog/scanner-dialog.component';
import { ConfirmDialogComponent } from './components/dialogs/confirm-dialog/confirm-dialog.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import { SettingsEditorComponent } from './components/settings/settings-editor/settings-editor.component';
import { FavoritesComponent } from './components/favorites/favorites.component';
import {SafePipe} from 'projects/library/src/pipes/safe/safe.pipe';
import {PipesModule} from 'projects/library/src/pipes/pipes.module';


@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    HomeComponent,
    ItemComponent,
    MediaSpectrumVisualizerComponent,
    MediaModelCollectionComponent,
    CollectionControllerComponent,
    MediaCollectionComponent,
    MediaCylinderCollectionComponent,
    MediaSphericalCollectionComponent,
    MediaGigapixelCollectionComponent,
    MediaCompareCollectionComponent,
    MediaImageCollectionComponent,
    MediaVideoCollectionComponent,
    MediaArCollectionComponent,
    InfoTextItemComponent,
    InfoDataItemComponent,
    InfoAbstractItemComponent,
    RelationTagComponent,
    RelationLinkComponent,
    MediaLocationCollectionComponent,
    ShareComponent,
    ScannerDialogComponent,
    ConfirmDialogComponent,
    SettingsEditorComponent,
    FavoritesComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    RoutingModule,
    FormsModule,
    PipesModule,

    // Material modules
    MatCommonModule,
    MatChipsModule,
    MatToolbarModule,
    MatMenuModule,
    // NoopAnimationsModule,
    MatSidenavModule,
    MatDialogModule,
    MatSnackBarModule,
    MatBottomSheetModule,
    MatListModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    FormsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    MatIconModule,
    MatExpansionModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatTreeModule,
    MatPaginatorModule,
    MatProgressBarModule,
    AngularSplitModule,
    // AngularSplitModule.forRoot(),
  ],
  providers: [
    ConfigService,
    ErrorService,
    HttpLoaderService,
    JsonService,
    LanguageService,
    LoggerService,
    NavigationService,
    NosleepService,
    { provide: APP_INITIALIZER , useFactory: preloadFactory, deps: [JsonService], multi: true}
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA , NO_ERRORS_SCHEMA ]
})
export class AppModule {

  constructor(
    private _iconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer,
  ) {

    this._iconRegistry.addSvgIcon('play', this._sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/audio/play.svg'));
    this._iconRegistry.addSvgIcon('pause', this._sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/audio/pause.svg'));
    this._iconRegistry.addSvgIcon('sync', this._sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/audio/sync.svg'));
    this._iconRegistry.addSvgIcon('muted', this._sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/audio/audio_muted.svg'));
    this._iconRegistry.addSvgIcon('unmuted', this._sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/audio/audio_unmuted.svg'));


/*
    this._iconRegistry.addSvgIcon('ar', this._sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/toolbar/ar.svg'));
    this._iconRegistry.addSvgIcon('compare', this._sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/toolbar/compare.svg'));
    this._iconRegistry.addSvgIcon('cylinder', this._sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/toolbar/cylinder.svg'));
    this._iconRegistry.addSvgIcon('gigapixel', this._sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/toolbar/gigapixel.svg'));
    this._iconRegistry.addSvgIcon('image', this._sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/toolbar/image.svg'));
    this._iconRegistry.addSvgIcon('location', this._sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/toolbar/location.svg'));
    this._iconRegistry.addSvgIcon('model', this._sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/toolbar/model.svg'));
    this._iconRegistry.addSvgIcon('spectrum', this._sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/toolbar/spectrum.svg'));
    this._iconRegistry.addSvgIcon('spherical', this._sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/toolbar/spherical.svg'));
    this._iconRegistry.addSvgIcon('video', this._sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/toolbar/video.svg'));
*/


  }

}
