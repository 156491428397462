<div class="component-container">

  <!--icons -->
  <mat-toolbar class="mat-elevation-z7 toolbar-icon" >
    <button mat-icon-button *ngFor="let mediaItem of this.item.media" (click)="mediaIconOnChanged(mediaItem)" class="toolbar-icon-button"  [class.selected]="mediaItem === mediaCollectionSelected" >
      <mat-icon>{{iconsByMedia[mediaItem.type]}}</mat-icon>
    </button>

    <button mat-icon-button aria-label="Language selection" [matMenuTriggerFor]="menu" class="toolbar-icon-button" >
      <mat-icon>translate</mat-icon>
    </button>
    <mat-menu #menu="matMenu" >
      <button mat-menu-item *ngFor="let lang of item.languages" (click)="itemLanguageSwap(lang)">{{lang.title}}</button>
    </mat-menu>


  </mat-toolbar>


  <!--contenidos-->
  <div class="split-container">

    <as-split direction="vertical" unit="percent" [gutterSize]="15" >

      <as-split-area size="50" minSize="10" >
        <div class="split-area" >

          <!--estirar hasta rellenar-->
          <div class="graphics stretchToFit">

            <!--spectrum-->
            <app-media-spectrum-visualizer *ngIf="mediaCollectionSelected.type === ItemMediaType.SPECTRUM" [id]="mediaCollectionSelected.type" [collection]="mediaCollectionSelected" (itemSelected)="mediaCollectionOnChanged($event)" (tip)="mediaCollectionOntip($event)" [itemLanguage]="itemService.language" [spectrum]="audioPlayer.spectrum" [metadata]="audioPlayer.metadata$" ></app-media-spectrum-visualizer>

            <!--model3DCollection-->
            <app-media-model-collection *ngIf="mediaCollectionSelected?.type === ItemMediaType.MODEL" [id]="mediaCollectionSelected.type" [collection]="mediaCollectionSelected" (itemSelected)="mediaCollectionOnChanged($event)" (tip)="mediaCollectionOntip($event)" [itemLanguage]="itemService.language" ></app-media-model-collection>

            <!--180-->
            <app-media-cylinder-collection *ngIf="mediaCollectionSelected?.type === ItemMediaType.CYLINDER" [id]="mediaCollectionSelected.type" [collection]="mediaCollectionSelected" (itemSelected)="mediaCollectionOnChanged($event)" (tip)="mediaCollectionOntip($event)" [itemLanguage]="itemService.language" ></app-media-cylinder-collection>

            <!--Spherical-->
            <app-media-spherical-collection *ngIf="mediaCollectionSelected?.type === ItemMediaType.SPHERICAL" [id]="mediaCollectionSelected.type" [collection]="mediaCollectionSelected" (itemSelected)="mediaCollectionOnChanged($event)" (tip)="mediaCollectionOntip($event)" [itemLanguage]="itemService.language" ></app-media-spherical-collection>

            <!--gigaPixelCollection-->
            <app-media-gigapixel-collection *ngIf="mediaCollectionSelected?.type === ItemMediaType.GIGAPIXEL" [id]="mediaCollectionSelected.type" [collection]="mediaCollectionSelected" (itemSelected)="mediaCollectionOnChanged($event)" (tip)="mediaCollectionOntip($event)" [itemLanguage]="itemService.language" ></app-media-gigapixel-collection>

            <!--compare-->
            <app-media-compare-collection *ngIf="mediaCollectionSelected?.type === ItemMediaType.COMPARE" [id]="mediaCollectionSelected.type" [collection]="mediaCollectionSelected" (itemSelected)="mediaCollectionOnChanged($event)" (tip)="mediaCollectionOntip($event)" [itemLanguage]="itemService.language" ></app-media-compare-collection>

            <!--Image-->
            <app-media-image-collection *ngIf="mediaCollectionSelected?.type === ItemMediaType.IMAGE" [id]="mediaCollectionSelected.type" [collection]="mediaCollectionSelected" (itemSelected)="mediaCollectionOnChanged($event)" (tip)="mediaCollectionOntip($event)" [itemLanguage]="itemService.language" ></app-media-image-collection>

            <!--videoCollection-->
            <app-media-video-collection *ngIf="mediaCollectionSelected?.type === ItemMediaType.VIDEO" [id]="mediaCollectionSelected.type" [collection]="mediaCollectionSelected" (itemSelected)="mediaCollectionOnChanged($event)" (tip)="mediaCollectionOntip($event)" [itemLanguage]="itemService.language" ></app-media-video-collection>


            <!--locationCollection-->
            <app-media-location-collection *ngIf="mediaCollectionSelected?.type === ItemMediaType.LOCATION" [id]="mediaCollectionSelected.type" [collection]="mediaCollectionSelected" (itemSelected)="mediaCollectionOnChanged($event)" (tip)="mediaCollectionOntip($event)" [itemLanguage]="itemService.language" ></app-media-location-collection>

            <!--Augmented Reality -->
            <app-media-ar-collection *ngIf="mediaCollectionSelected?.type === ItemMediaType.AR" [id]="mediaCollectionSelected.type" [collection]="mediaCollectionSelected" (itemSelected)="mediaCollectionOnChanged($event)" (tip)="mediaCollectionOntip($event)" ></app-media-ar-collection>

            <!--tip-->
            <div class="tip" *ngIf="tip" (click)="tip=null" [@opacity]><span>{{tip}}</span></div>

          </div>


          <div class="media-item-title">
            <span *ngIf="mediaItemSelected">{{ languageService.translate(mediaItemSelected , 'title' , itemService.language.code) }}</span>&nbsp;
          </div>


        </div>



      </as-split-area>



      <as-split-area >

        <div class="split-area">


          <div>
            <!-- -->
            <mat-tab-group animationDuration="0ms" (selectedTabChange)="infoTabOnChanged($event)" class="mat-elevation-z7" >

              <!--textos traducibles y relaciones    -->
              <mat-tab *ngFor="let infoItem of this.item.information" label="{{ languageService.translationGet('TABS' , infoItem.type) }}" ></mat-tab>

            </mat-tab-group>
          </div>


          <!--contenidos de las tabs -->
          <div class="information stretchToFit">

            <!-- Text -->
            <app-info-text-item *ngIf="infoSelected?.type === ItemInfoType.TEXT" [item]="infoSelected" [lang]="this.itemService.language" [metadata]="audioPlayer.metadata$" ></app-info-text-item>

            <!-- Data -->
            <app-info-data-item *ngIf="infoSelected?.type === ItemInfoType.DATA" [item]="infoSelected" [lang]="this.itemService.language" ></app-info-data-item>

            <!-- Tags -->
            <app-relation-tag *ngIf="infoSelected?.type === ItemInfoType.TAG" [item]="infoSelected" [lang]="this.itemService.language" ></app-relation-tag>

            <!-- Links -->
            <app-relation-link *ngIf="infoSelected?.type === ItemInfoType.LINK" [item]="infoSelected" [lang]="this.itemService.language" ></app-relation-link>

          </div>

        </div>
      </as-split-area>

    </as-split>
  </div>


  <!-- audio controls -->
  <div class="audioPlayer">

    <div class="controls">

      <button *ngIf="!playSync" mat-button (click)="audioPlaybackToggle()" >
        <mat-icon *ngIf="audioPlayer && !audioPlayer.playing" svgIcon="play" aria-hidden="false" aria-label="resume"></mat-icon>
        <mat-icon *ngIf="audioPlayer && audioPlayer.playing" svgIcon="pause" aria-hidden="false" aria-label="pause"></mat-icon>
      </button>

      <button *ngIf="playSync" mat-button (click)="audioSyncToggle()" >
        <mat-icon *ngIf="audioPlayer && !audioPlayer.playing" svgIcon="sync" aria-hidden="false" aria-label="resume"></mat-icon>
        <mat-icon *ngIf="audioPlayer && audioPlayer.playing" svgIcon="pause" aria-hidden="false" aria-label="pause"></mat-icon>
      </button>

    </div>

    <div class="progress" (click)="audioPlaybackSeek($event.offsetX / road.offsetWidth)">
      <div #road class="road" style="pointer-events: none;" ></div>
      <div class="runner" style="width:{{ 100 * audioPlayer.progress  }}%; pointer-events: none;"></div>
    </div>

    <div class="volume">
      <button mat-button (click)="audioMuteToggle()" >
        <mat-icon *ngIf="audioPlayer && !audioPlayer.muted" svgIcon="unmuted" aria-hidden="false" aria-label="unmuted"></mat-icon>
        <mat-icon *ngIf="audioPlayer && audioPlayer.muted" svgIcon="muted" aria-hidden="false" aria-label="muted"></mat-icon>
      </button>
    </div>


    <div class="time">
      {{ audioPlayer.position | number : "3.0-0" }}
    </div>
  </div>
</div>
