import { Pipe, PipeTransform } from '@angular/core';



@Pipe({
  name: 'timestampToDate'
})
export class TimestampToDate implements PipeTransform {

  transform(ts: number): Date {
    return new Date(ts);
  }

}
