import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogData} from 'projects/library/src/model/confirm-dialog-data';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData

  ) {
    //
  }

  ngOnInit(): void {
    //
  }

  /*
  Ademas de emitir el resultado en el modo tradicional de Angular (event) he de hacerlo (ademas) con un callback.
  El evento se desvincula de la accion directa de pulsar con el dedo, y en determinados contextos NO me vale.

  Por ejemplo:
  En Safari iOS quiero reproducir un video con audio al pulsar en el boton.
  En el modo clasico, la confirmacion va con un evento que NO se emite directamente, sino al terminar la animacion de Angular
  Por lo tanto, al recibir la respuesta, está fuera del loop y el video no se reproduce !!

  Esta solucion de un callback, NO me gusta ni me parece elegante, pero soluciona este problema
   */
  dialogAccepted(b: boolean): void {
    this.data.callback ? this.data.callback(b) : void(0);
  }

}
