import {Injectable} from '@angular/core';
import {LanguageService} from 'projects/library/src/services/language/language.service';

@Injectable({
  providedIn: 'root'
})
export class TipService {

  private _emitted: string[];

  constructor(
    public languageService: LanguageService
  ) {
    this._emitted = [];
  }


  public get(term: string): string {
    if (this._emitted.includes(term)){
      return null;
    }

    this._emitted.push(term);
    const translatedTip: string = this.languageService.translationGet('TIPS' , term);
    return translatedTip;
  }





}
