<div class="favorites-container {{itemService.language.direction}}"  >
  <mat-toolbar >
  <input #search type="text" placeholder="{{filterPlaceholder}}" (change)="filter(search.value)">
  <span class="stretchToFit"></span>
  <mat-icon (click)="add()" style="cursor: pointer">add_circle</mat-icon>
</mat-toolbar>

<mat-divider></mat-divider>

<mat-nav-list>

  <!--favorites-->
  <ng-container *ngFor="let f of favorites">

    <mat-list-item>
      <img mat-list-icon [src]="f.icon" (click)="open(f)">
      <span mat-line (click)="open(f)">{{languageService.translate(f , 'title' , itemService.language.code)}}</span>
      <mat-icon (click)="remove(f)">delete</mat-icon>
    </mat-list-item>
    <mat-divider></mat-divider>

  </ng-container>

</mat-nav-list>

</div>
