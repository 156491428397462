import {Injectable, NgZone} from '@angular/core';
import {ActivatedRoute, ActivationEnd, NavigationExtras, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {INavigationQueryParams} from './i-navigation-query-params';
import {LoggerInterface} from '../logger/logger.interface';
import {LoggerService} from '../logger/logger.service';


@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  public static __config: any;
  private navigationOptions: NavigationExtras;
  private logger: LoggerInterface;






  /* STATIC CONFIG ********************************************************** */

  public static setConfig(d: any): void {
    NavigationService.__config = d;
  }

  public static getConfig(): any {
    return NavigationService.__config ;
  }

  public get config(): any {
    return NavigationService.getConfig() ;
  }








  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private zone: NgZone,
  ) {

    this.logger = LoggerService.getLogger('NavigationService' );
    this.navigationOptions = this.config;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.routerListen();
  }



  private getNavigationExtras( newQueryParams: any = {}  ): NavigationExtras {

    const fixedParams: INavigationQueryParams = {
      // language: this.apiService.languageSelected,
    };
    const oParams: any = Object.assign(fixedParams , newQueryParams  );


    const oExtras: NavigationExtras = Object.assign({} , this.navigationOptions);
    oExtras.queryParams = oParams;
    return oExtras ;
  }









  /**
   * Go to home
   */
  public navigateToHome(): void {
    this.navigateTo('');
  }



  /**
   * Go to ...
   */
  public navigateTo( ...segments: string[] ): void {
    const relativePath: string[] = [ ...segments ];
    this.logger.ENABLED && this.logger.info('navigateTo' , relativePath.join(',') );
    this.navigateTriggerInsideZone(relativePath ,  this.getNavigationExtras() );
  }



  /**
   * Trigger navigation inside NgZone
   */
  private navigateTriggerInsideZone(arrSegments: string[] , extras: NavigationExtras): void {
    this.zone.run( () => {
      this.router.navigate(arrSegments ,  extras);
    });
  }










  /* ********************************************************** ROUTER ********************************************************** */


  // Escuchamos eventos al cambiar la navegacion
  private routerListen(): void  {

    // Get params and data
    this.router.events.pipe(
      filter(e => e instanceof ActivationEnd)
    )
      .subscribe(
        this.routerOnActivationEnd.bind(this)
      );
  }


  public reload(): void {
    window.location.reload();
  }


  /**
   * Remember last navigation (excerpts location and language)
   * @param e: RoutesRecognized
   */
  private routerOnActivationEnd(e: ActivationEnd): void  {
    this.logger.ENABLED && this.logger.info('ActivactionEnd' , e.snapshot.queryParams );
  }



  public navigateExternal(url: string): void {
    window.location.href = url;
  }


}
