/* ******************************************** DATE UTILS ******************************************** */
export function getMySqlDate(d: Date = new Date()): string {

  const yyyy: string = String(d.getFullYear());
  const mm: string = String(d.getMonth() + 1).padStart(2 , '0');
  const dd: string = String(d.getDate() ).padStart(2 , '0');
  const h: string = String(d.getHours() ).padStart(2 , '0');
  const m: string = String(d.getMinutes() ).padStart(2 , '0');
  const s: string = String(d.getSeconds() ).padStart(2 , '0');
  const mss: string = String(d.getMilliseconds() ).padStart(3 , '0');

  return `${yyyy}-${mm}-${dd} ${h}:${m}:${s} ${mss}`;
}
