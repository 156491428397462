/* eslint-disable @typescript-eslint/no-inferrable-types */
import { EventEmitter, Injectable } from '@angular/core';



import { BehaviorSubject, of } from 'rxjs';
import {concatMap} from 'rxjs/operators';

import {ILanguage} from 'projects/library/src/services/language/i-language';
import {ITranslation} from 'projects/library/src/services/language/i-translation';
import {LoggerInterface} from 'projects/library/src/services/logger/logger.interface';
import {LoggerService} from 'projects/library/src/services/logger/logger.service';
import {HttpLoaderService} from 'projects/library/src/services/http-loader/http-loader.service';
import {HttpLoadMode} from 'projects/library/src/services/http-loader/http-load-mode';
import {IGlossaryCollection} from 'projects/library/src/services/language/i-glossary-collection';
import {ITranslatable} from 'projects/library/src/services/language/i-translatable';
import {ITranslatedGlossary} from 'projects/library/src/services/language/i-translated-glossary';


@Injectable({
  providedIn: 'root'
})
export class LanguageService {


  /* STATIC  ********************************************************** */
  public static __config: any;
  private static __languageCollection: ILanguage[];
  private static __translationCollection: ITranslation[];


  private _logger: LoggerInterface;
  private _languageDefault: ILanguage ;
  private _languageSelected: ILanguage ;


  public complete$: EventEmitter<ILanguage>;
  public error$: EventEmitter<string>;
  public code$: BehaviorSubject<string>;





  public static setConfig(d: any): void {
    LanguageService.__config = d;
  }

  public static getConfig(): any {
    return LanguageService.__config ;
  }

  public static setLanguageCollection(c: ILanguage[] ): void {
    LanguageService.__languageCollection = c;
  }

  public static setTranslationCollection(c: ITranslation[] ): void {
    LanguageService.__translationCollection = c;
  }







  constructor(
    private loggerService: LoggerService,
    private _httpLoaderService: HttpLoaderService
  ) {
    this._logger = this.loggerService.getLogger('LanguageService' );

    this.complete$ = new EventEmitter<ILanguage>();
    this.error$ = new EventEmitter<string>();
    this.code$ = new BehaviorSubject<string>(LanguageService.__config.default);

    this.languageInit();
  }


  public get config(): any {
    return LanguageService.getConfig() ;
  }

  get languageCollection(): ILanguage[] {
    return LanguageService.__languageCollection;
  }
  set languageCollection(value: ILanguage[]) {
    LanguageService.__languageCollection = value;
  }

  get translationCollection(): ITranslation[] {
    return LanguageService.__translationCollection;
  }
  set translationCollection(value: ITranslation[]) {
    LanguageService.__translationCollection = value;
  }



  public start(): void {

    this._httpLoaderService.httpLoad(this.config.url_language , HttpLoadMode.GET).pipe(
      concatMap((ipResponse: ILanguage[] , index: number) => {

        // Validate Languae
        if (!ipResponse){
          this.error$.emit('Error while retrieving language list');
          return of(null);
        }

        // Remember langs
        this.languageCollection = ipResponse;

        // Trigger tranlsations
        return this._httpLoaderService.httpLoad<ITranslation[]>(this.config.url_translation , HttpLoadMode.GET);
      })
    ).subscribe(this.translationsOnLoaded.bind(this));

  }




  private translationsOnLoaded(arrTrans: ITranslation[]): void  {
    this.translationCollection = arrTrans ;

    this.languageInit();
  }



  private languageInit(): void  {
    this.languageDefaultFind();
    this.languageClear();
    this.complete$.emit(this._languageSelected);
  }



  get languageDefault(): ILanguage {
    return this._languageDefault;
  }

  set languageDefault(value: ILanguage) {
    this._languageDefault = value;
    this._logger.ENABLED && this._logger.log('language default set to' , value.code  );
  }

  get languageSelected(): ILanguage {
    return this._languageSelected;
  }

  set languageSelected(value: ILanguage) {
    this._languageSelected = value;
    this._logger.ENABLED && this._logger.log('switching language to  :: ' , this._languageSelected.code );
    this.code$.next(this._languageSelected.code);
  }

  get languageSelectedCode(): string {
    return this.languageSelected.code;
  }


  set languageSelectedCode(code: string) {

    // search for language whom code matches with given value
    const foundLanguage: ILanguage = this.languageCollection.find(lang => lang.code === code);

    // If found, set and confirm
    if (foundLanguage){
      this.languageSelected = foundLanguage;
    }

    // Otherwise
    this._logger.ENABLED && this._logger.warn('code not found :: ' , code );
  }



  /**
   * Finds the language flagged as "default"
   */
  private languageDefaultFind(): void  {

    // Browser language
    let browserLanguage: ILanguage = null;
    const navigatorAny: any = navigator as any;
    const browserLocale: string =  navigator.language || navigatorAny.userLanguage ;
    if (browserLocale){
      const noLetter: RegExp = /[^a-zA-Z0-9]/;
      const browserCode: string = browserLocale.split(noLetter)[0];
      browserLanguage = this.languageCollection.find(value => value.code === browserCode );
      this._logger.ENABLED && this._logger.log('browser language found  :: ' , browserLocale , browserCode );
    }

    // return browser or default
    // this.languageDefault = browserLanguage || this.languageCollection.find(value => value.default === true );
    this.languageDefault = browserLanguage || this.languageCollection.find(value => value.code === this.config.default );
  }



  public languageClear(): void {
    this.languageSelected = this.languageDefault ;
  }















  /* TRADUCCIONES  ********************************************************************************* */


  public translationFind(theme: string): ITranslation {
    return this.translationCollection.find(value => value.theme === theme );
  }


  public translationGet(theme: string , term: string = 'title' , lang: string = this._languageSelected.code ): string {
    let translatedTerm: string;

    try{
      const oTranslation: ITranslation = this.translationFind(theme);
      translatedTerm = this.translate(oTranslation , term , lang);
    }catch (e: any ) {
      this._logger.ENABLED && this._logger.error('translate Translation not found :: ' , theme + ' -> ' + term + ' -> ' + lang );
      translatedTerm = '';
    }
    return translatedTerm;
  }

  public translatedTermGet(translatedGlossaryCollection: IGlossaryCollection, term: string = 'title', lang: string = this._languageSelected.code ): string {
    const oTerm: string = translatedGlossaryCollection[lang][term];
    if (!oTerm){
      throw new Error('Translation not found');
    }
    return oTerm || '';
  }


  public translate(oTranslatable: ITranslatable, term: string = 'title', lang: string = this._languageSelected.code ): string {
    try{
      return this.translatedTermGet(oTranslatable.glossaryCollection , term , lang);
    }catch (e){
      return '';
    }
  }

  public translationHasLanguage(oTranslatable: ITranslatable, lang: string = this._languageSelected.code): boolean {
    return lang in oTranslatable.glossaryCollection;
  }

  public translationHasTerm(oTranslatable: ITranslatable, term: string = 'title', lang: string = this._languageSelected.code): boolean {
    return this.translationHasLanguage(oTranslatable , lang) &&  this.translatedTermGet(oTranslatable.glossaryCollection , lang , term) != null;
  }

  public translationFieldsAvailable(oTranslatable: ITranslatable, lang: string): string[] {
    const arrFields: string[] = [];
    const oGlossary: ITranslatedGlossary = oTranslatable.glossaryCollection[lang] ;
    for (const fieldName in oGlossary){
      if (oGlossary.hasOwnProperty(fieldName)){
        arrFields.push(fieldName);
      }
    }
    return arrFields;
  }

}

