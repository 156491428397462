import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replacer'
})
export class ReplacerPipe implements PipeTransform {

  transform(template: string, toFind: string , toReplace: string ): string {
    return template.replace(toFind , toReplace);
  }

}
