import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'crop'
})
export class CropPipe implements PipeTransform {

  transform(value: string , limit: number): string {
    const tail: string = '...';
    const l: number = value.length;

    // Entra....
    if (l <= limit){
      return value;
    }

    else {
      let crop: string = value.substring(0 , limit - tail.length );
      crop += tail;
      return crop;
    }
  }

}
