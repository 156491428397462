import { Component, OnInit } from '@angular/core';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {

  constructor(private _bottomSheetRef: MatBottomSheetRef<ShareComponent>) {}


  ngOnInit(): void {
    //
  }

  openLink(socialUrl: string): void {
    alert('sharing :: ' + location.href + ' in ' + socialUrl);
    this._bottomSheetRef.dismiss();
  }


}
