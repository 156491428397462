import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {MediaCollectionComponent} from 'projects/visitor/src/app/components/media/media-collection/media-collection.component';
import {MediaImage} from 'projects/library/src/model/item-model';

@Component({
  selector: 'app-media-image-collection',
  templateUrl: './media-image-collection.component.html',
  styleUrls: ['./media-image-collection.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MediaImageCollectionComponent extends MediaCollectionComponent implements OnInit, OnDestroy, AfterViewInit {

  public selectedImage: MediaImage;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  collectionIndexOnChanged(index: number): void {
    super.collectionIndexOnChanged(index);

    this.selectedImage = this.selectedItem as MediaImage;
  }


}
