import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {InfoAbstractItemComponent} from 'projects/visitor/src/app/components/info/info-abstract-item/info-abstract-item.component';
import {LanguageService} from 'projects/library/src/services/language/language.service';
import {RelationLinkCollection, RelationLinkItem} from 'projects/library/src/model/item-model';
import {NavigationService} from 'projects/library/src/services/navigation/navigation.service';
import {NavigationSegments} from 'projects/visitor/src/app/navigation/navigation-segments.enum';
import {ItemService} from 'projects/library/src/services/item/item.service';

@Component({
  selector: 'app-relation-link',
  templateUrl: './relation-link.component.html',
  styleUrls: ['./relation-link.component.scss']
})
export class RelationLinkComponent extends InfoAbstractItemComponent implements OnInit, OnDestroy, AfterViewInit {

  public linkItem: RelationLinkCollection;

  constructor(
    languageService: LanguageService,
    private navigationService: NavigationService,
    private itemService: ItemService
  ) {
    super(languageService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  protected itemOnReady(): void {
    super.itemOnReady();
    this.linkItem = this.item as RelationLinkCollection;
  }

  public relationClick(rel: RelationLinkItem): void {
    const currID: string = this.itemService.item.itemID;
    const newURL: string = location.href.replace(currID , rel.id );
    this.navigationService.navigateExternal(newURL);
  }
}
