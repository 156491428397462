/* tslint:disable:no-string-literal */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AudioEffectsService {

  AudioContext = window.AudioContext || window['webkitAudioContext'];


  private context: AudioContext;
  private gainNode: GainNode;
  private buffer: AudioBuffer;
  private source: AudioBufferSourceNode;

  private library: Map<string, AudioBuffer>;



  constructor() {
    this.context = new AudioContext(); // Make it crossbrowser
    this.gainNode = this.context.createGain();
    this.gainNode.gain.value = 1; // set volume to 100%

    this.loadLibrary();
  }


  public unlock(): void {
    console.log('unlocking');

    // create empty buffer and play it
    this.buffer = this.context.createBuffer(1, 1, 22050);

    this.source = this.context.createBufferSource();
    this.source.buffer = this.buffer;
    this.source.connect(this.context.destination);

    // play the file. noteOn is the older version of start()
    this.source.start ? this.source.start(0) : this.source['noteOn'](0);

  }

  private async loadLibrary(): Promise<void> {
    this.library = new Map<string, AudioBuffer>();
    this.library.set('scan' , await this.loadAudio('./assets/audio/scan.mp3') );
  }



  private async loadAudio(url: string): Promise<AudioBuffer> {
    const oArrayBuffer: ArrayBuffer = await fetch('./assets/audio/scan.mp3').then(response => response.arrayBuffer());
    const oAudioBuffer: AudioBuffer = await this.context.decodeAudioData(
      oArrayBuffer,
        audioBuffer => audioBuffer,
        error => console.error(error)
      );
    return oAudioBuffer;
  }


  play(id: string): void {
    try{
      const source: AudioBufferSourceNode = this.context.createBufferSource();
      source.buffer = this.library.get(id);
      source.connect(this.context.destination);
      source.start();
    }catch (e){
      console.error(e);
    }
  }



}
