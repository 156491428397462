<div class="app-container" [class]="[this.languageService.languageSelected.direction ]"   >

  <!--toolbar principal-->
  <mat-toolbar color="primary" class="mat-elevation-z6" style="z-index: 3">

    <button mat-icon-button class="example-icon" aria-label="Menu" (click)="drawer.toggle()" >
      <mat-icon>menu</mat-icon>
    </button>

    <span>Prosodik</span>

    <span class="stretchToFit"></span>

    <button *ngIf="!fullscreenService.isFullscreenEnabled" mat-icon-button aria-label="Fullscreen" (click)="fullscreenService.toggle()">
      <mat-icon *ngIf="!fullscreenService.fullscreen">zoom_out_map</mat-icon>
      <mat-icon *ngIf="fullscreenService.fullscreen">zoom_in_map</mat-icon>
    </button>

    <button mat-icon-button aria-label="QR Scanner" (click)="scannerOpen()">
      <mat-icon>qr_code</mat-icon>
    </button>

    <button mat-icon-button aria-label="Share" (click)="shareOpen()" >
      <mat-icon>share</mat-icon>
    </button>

    <button mat-icon-button aria-label="Share" (click)="favoriteOpen()" >
      <mat-icon >{{favoriteService.exists(itemService.item?.itemID) ? 'favorite' : 'favorite_border' }}</mat-icon>
    </button>


  </mat-toolbar>

  <!--cajonera-->
  <mat-drawer-container class="drawer-container">

    <!--izquierda-->
    <mat-drawer #drawer class="drawer-sidenav" mode="push" >

      <app-settings-editor *ngIf="drawer.opened" ></app-settings-editor>

    </mat-drawer>

    <!--derecha-->
    <div class="drawer-content">
      <router-outlet #outlet="outlet" ></router-outlet>
    </div>

  </mat-drawer-container>

</div>
