import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {InfoAbstractItemComponent} from 'projects/visitor/src/app/components/info/info-abstract-item/info-abstract-item.component';
import {LanguageService} from 'projects/library/src/services/language/language.service';
import {RelationTagCollection, RelationTagItem} from 'projects/library/src/model/item-model';

@Component({
  selector: 'app-relation-tag',
  templateUrl: './relation-tag.component.html',
  styleUrls: ['./relation-tag.component.scss']
})
export class RelationTagComponent extends InfoAbstractItemComponent implements OnInit, OnDestroy, AfterViewInit {

  public tagCollection: RelationTagCollection;

  constructor(
    languageService: LanguageService
  ) {
    super(languageService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  protected itemOnReady(): void {
    super.itemOnReady();
    this.tagCollection = this.item as RelationTagCollection;
  }

  public relationClick(rel: RelationTagItem): void {
    alert('Opening Tag :: ' + rel.id );
  }

}
