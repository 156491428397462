import { Injectable } from '@angular/core';
import {LoggerInterface} from 'projects/library/src/services/logger/logger.interface';
import {LoggerService} from 'projects/library/src/services/logger/logger.service';
import {ScriptLoaderService} from 'projects/library/src/services/script-loader/script-loader.service';
// import NoSleep from 'nosleep.js';

declare let NoSleep;

@Injectable({
  providedIn: 'root'
})
export class NosleepService {

  private _logger: LoggerInterface;
  private _nosleep: typeof NoSleep;



  constructor(
    private _scriptLoaderService: ScriptLoaderService
  ) {
    this._logger = LoggerService.getLogger('NosleepService');
    this._scriptLoaderService.loadScript('./assets/scripts/nosleep/NoSleep.js');
  }


  private create(): void {
    if (!this._nosleep){
      this._nosleep = new NoSleep();
    }
  }




  public toggle(): void{
    if (this._nosleep.isEnabled){
      this.disable();
    } else {
      this.enable();
    }
  }


  public enable(): void {
    this.create();

    if (!this._nosleep.isEnabled){
      this._nosleep.enable();
    }
    this._logger.ENABLED && this._logger.log('enabled');
  }


  public disable(): void {
    if (this._nosleep.isEnabled){
      this._nosleep.disable();
    }
    this._logger.ENABLED && this._logger.log('disabled');
  }


}
