import {animate , style, transition, trigger} from '@angular/animations';


/*
Usada en el chat middle al añadir y quitar filas de mensajes
*/
export const opacityAnimation =
  trigger('opacity', [
    transition(':enter', [
        style({
          opacity: 0,
        }),
        animate('500ms', style({
          opacity: 1,
        })),
      ],
    ),
    transition(':leave', [
      animate('500ms', style({
        opacity: 0 ,
      }))
    ])
  ]);


