import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {MediaCollectionComponent} from 'projects/visitor/src/app/components/media/media-collection/media-collection.component';
import {MediaSpherical, MediaSphericalHotspot} from 'projects/library/src/model/item-model';
import {LanguageService} from 'projects/library/src/services/language/language.service';

declare var pannellum;

@Component({
  selector: 'app-media-spherical-collection',
  templateUrl: './media-spherical-collection.component.html',
  styleUrls: ['./media-spherical-collection.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MediaSphericalCollectionComponent extends MediaCollectionComponent implements OnInit, OnDestroy, AfterViewInit {


  public selectedSpherical: MediaSpherical;
  public viewer: any;
  public sceneDefault: any;

  constructor(
    public languageService: LanguageService
    // private ngZone: NgZone
  ) {
    super();

    this.sceneDefault = {
      type: 'equirectangular',
      panorama: '',
      autoLoad: true,
      autoRotate: -2,
      pitch: 2.3,
      yaw: -135.4,
      hfov: 120,
      hotSpots: []
    };
/*
    this.loadScript('./assets/scripts/pannellum/pannellum.js' , false);
    this.loadCSS('./assets/scripts/pannellum/pannellum.css' );
*/
  }


  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.viewer?.destroy();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  protected collectionOnReady(): void {
    super.collectionOnReady();
    //
  }


  protected containerOnResized(entries): void {
    super.containerOnResized(entries);
    this.viewer?.resize();
  }

  collectionIndexOnChanged(index: number): void {
    super.collectionIndexOnChanged(index);
    this.selectedSpherical = this.selectedItem as MediaSpherical ;

    setTimeout( () => {
      this.viewerCreate();
    } , 10 );
  }


  async viewerCreate(): Promise<void> {
    this.viewer?.destroy();

    // Cargamos la url 1º (para que tarde un poco y se cachee)
    await fetch(this.selectedSpherical.url);

    // Parseamos hotspots
    const oHotSpots: MediaSphericalHotspot[] = JSON.parse(JSON.stringify(this.selectedSpherical.hotSpots));
    for (const h of oHotSpots){
      h.text = this.languageService.translate(h , 'title' , this.itemLanguage.code ) ;
      delete h.glossaryCollection;
    }


    // Creamos escena
    const oScene: any = Object.assign( {} , this.sceneDefault , {hotSpots: oHotSpots} , {panorama: this.selectedSpherical.url});
    this.viewer = pannellum.viewer(this.container , oScene );

/*
    this.ngZone.runOutsideAngular(() => {
    });
*/
  }



/*
      {
      type: 'equirectangular',
      panorama: 'https://pannellum.org/images/alma.jpg',
      // preview: '/images/tocopilla-preview.jpg',
      autoLoad: true,
      autoRotate: -2,
      pitch: 2.3,
      yaw: -135.4,
      hfov: 120,
      hotSpots: [
          {
              pitch: 14.1,
              yaw: 1.5,
              type: 'info',
              text: 'Baltimore Museum of Art',
              URL: 'https://artbma.org/'
          },
          {
              pitch: -9.4,
              yaw: 222.6,
              type: 'info',
              text: 'Art Museum Drive'
          },
          {
              pitch: -0.9,
              yaw: 144.4,
              type: 'info',
              text: 'North Charles Street'
          }
      ]
    }
 */
}
