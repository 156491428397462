import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {InfoAbstractItemComponent} from 'projects/visitor/src/app/components/info/info-abstract-item/info-abstract-item.component';
import {LanguageService} from 'projects/library/src/services/language/language.service';
import {IGlossaryCollection} from 'projects/library/src/services/language/i-glossary-collection';
import {ITranslatedGlossary} from 'projects/library/src/services/language/i-translated-glossary';
import {InfoItem, InfoText} from 'projects/library/src/model/item-model';
import {ItemService} from 'projects/library/src/services/item/item.service';

@Component({
  selector: 'app-info-data-item',
  templateUrl: './info-data-item.component.html',
  styleUrls: ['./info-data-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class InfoDataItemComponent extends InfoAbstractItemComponent implements OnInit, OnDestroy, AfterViewInit {

  public textItem: InfoText;
  public translatableTerms: string[] = [];

  constructor(
    languageService: LanguageService,
    public itemService: ItemService
  ) {
    super(languageService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    // recorro los campos traducibles de la ficha y monto un array
    const objGlossary: ITranslatedGlossary = this.textItem.glossaryCollection[this.lang.code];
    for (const term in objGlossary) {
      if (objGlossary.hasOwnProperty(term)){
        this.translatableTerms.push(term);
      }
    }
  }

  protected itemOnReady(): void {
    super.itemOnReady();
    this.textItem = this.item as InfoText;
  }



}
